import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IReviewItem } from '../../../redux/api/types/reviews';
import { Star } from '../../../view/Stars';

interface IProps {
  feedback: IReviewItem;
}

export const FeedbackItem: FC<IProps> = ({ feedback }) => {
  const { t } = useTranslation();

  const { reviewDate, rating, user, reviewText, payment } = feedback;
  return (
    <div
      className={`w-full flex items-center justify-between p-[1.302vw] bg-lightGray rounded-[0.625vw]
      md:rounded-[1.563vw] md:p-[3.255vw] xs:rounded-[3.2vw] xs:p-[3.2vw] xs:flex-col xs:items-start`}
    >
      <div className="flex flex-col gap-[0.833vw] md:gap-[2.083vw] xs:gap-[3.447vw] xs:w-full">
        <div className="flex items-center gap-[1.345vw] xs:flex-wrap xs:justify-between">
          <p
            className={`leading-[0.938vw] text-mainBlack text-[0.625vw]
            md:text-[1.563vw] md:leading-[2.344vw] xs:text-[3.2vw] xs:leading-[4.8vw]
            xs:w-[40%]`}
          >
            {new Date(reviewDate).toLocaleDateString('ru-RU', {
              year: 'numeric',
              day: '2-digit',
              month: '2-digit',
            })}
          </p>
          <div className="flex items-center gap-[0.521vw] md:gap-[0.502vw] xs:gap-[1.667vw] md:mr-[2.865vw]">
            <p
              className={`font-bold leading-[1.25vw] text-mainBlack text-[0.833vw]
              md:text-[1.823vw] md:leading-[3.125vw] xs:text-[3.733vw] xs:leading-[6.4vw]`}
            >
              {t('rating.mark')}
            </p>
            <Star
              rating={rating}
              className="gap-[0.521vw]"
              size="w-[1.042vw] h-[1.042vw] md:w-[2.344vw] md:h-[2.344vw] xs:w-[4.8vw] xs:h-[4.8vw]"
              full={false}
            />
          </div>
          <p className="font-medium text-[0.625vw] leading-[0.938vw]  md:text-[1.563vw] md:leading-[2.344vw] xs:text-[3.2vw] xs:leading-[4.8vw]">
            {t('rating.for')}{' '}
            <span className="font-bold leading-[1.25vw] text-mainBlack text-[0.833vw] md:text-[1.823vw] md:leading-[3.125vw] xs:text-[3.733vw] xs:leading-[6.4vw]">{`${user.firstName} ${user.lastName}`}</span>
          </p>
        </div>
        <p className="text-[0.833vw] leading-[1.25vw] md:text-[1.823vw] md:leading-[2.604vw] xs:text-[3.733vw] xs:leading-[5.333vw]">
          {reviewText}
        </p>
      </div>
      <div className="flex items-center gap-[0.625vw] md:flex-col xs:flex-row">
        <p className="font-medium text-[0.625vw] leading-[0.938vw] md:text-[1.563vw] md:leading-[2.344vw] xs:text-[3.2vw] xs:leading-[4.8vw]">
          {t('rating.tips')}
        </p>
        <p className="font-bold text-[1.042vw] leading-[1.563vw] md:text-[2.344vw] md:leading-[3.906vw] xs:text-[4.8vw] xs:leading-[8vw]">
          {payment?.amount} ₾
        </p>
      </div>
    </div>
  );
};
