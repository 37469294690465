import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { Currency, SUBSCRIPTION_PRICES } from '../../../constants/base';
import { policy } from '../../../pages/Payment/pay/config';
import { useCreateRecurringMutation } from '../../../redux/api/payment';
import { SubPeriod } from '../../../redux/api/types';
import { useAppSelector } from '../../../redux/hooks';
import { replacePolicyLabel } from '../../../utils/replacePolicyText';
import { Button } from '../../../view/Button';
import { Checkbox } from '../../../view/Checkbox';
import { Select } from '../../../view/Select';

interface IProps {
  className?: string;
}

const CURRENCY_SYMBOLS = {
  [Currency.EUR]: '€',
  [Currency.USD]: '$',
  [Currency.GEL]: '₾',
};

const BuyMenu: FC<IProps> = ({}) => {
  const {
    t,
    i18n: { language: lang },
  } = useTranslation();
  const { id } = useAppSelector(state => state.user);
  const [createRecurringPayment] = useCreateRecurringMutation();

  const handleSubmit = (values: { period?: SubPeriod; currency: Currency }) => {
    if (values.period) {
      createRecurringPayment({ period: values.period, userId: id, currency: values.currency })
        .unwrap()
        .then(data => {
          const link = document.createElement('a');
          link.href = data || '';
          link.click();
        });
    }
  };
  const formik = useFormik({
    initialValues: {
      period: undefined,
      aggrement: false,
      currency: Currency.GEL,
    },
    onSubmit: handleSubmit,
  });

  const correctLang = lang ? (lang.split('-').length > 1 ? lang.split('-')[0] : lang) : 'en';

  const policyText = t('pay.terms');
  const nextText = replacePolicyLabel(policyText, correctLang, policy);

  return (
    <div className="bg-white p-[2.604vw] md:px-[3.255vw] md:py-[5.208vw] xs:py-[8vw] xs:px-[5.333vw]">
      <div className="w-full flex justify-center items-center flex-col gap-4">
        <p className="font-semibold text-[24px]">{t('foodMenu.buySubTitle')}</p>
        <div className="flex items-center gap-2 xs:flex-col">
          <Select
            placeholder={t('foodMenu.periodTitle') ?? ''}
            options={[
              {
                title: 'foodMenu.month',
                value: SubPeriod.MONTH,
              },
              {
                title: 'foodMenu.month2',
                value: SubPeriod.MONTH_3,
              },
              {
                title: 'foodMenu.month3',
                value: SubPeriod.MONTH_6,
              },
              {
                title: 'foodMenu.year',
                value: SubPeriod.YEAR,
              },
            ]}
            onChange={value => formik.setFieldValue('period', value)}
          />
          <Select
            placeholder={t('') ?? ''}
            options={[
              {
                title: 'GEL',
                value: Currency.GEL,
              },
              {
                title: 'USD',
                value: Currency.USD,
              },
              {
                title: 'EUR',
                value: Currency.EUR,
              },
            ]}
            onChange={value => formik.setFieldValue('currency', value)}
          />
        </div>
        <p>{t('foodMenu.periodWarn')}</p>
        <Checkbox
          labelText={nextText}
          checked={formik.values.aggrement}
          toggle={() => {
            formik.setFieldValue('aggrement', !formik.values.aggrement);
          }}
          classNames="items-start xs:ml-0"
          labelClassnames="xs:text-[3.2vw] xs:leading-[4.8vw]"
          name="terms"
          // disabled={isDisabled}
          withShadow={true}
        />
        <Button
          onClick={formik.submitForm}
          disabled={!formik.values.aggrement || !formik.values.period}
        >
          {t('foodMenu.buySub')}
          {formik.values.period &&
            `: ${SUBSCRIPTION_PRICES[formik.values.period as SubPeriod][formik.values.currency]} ${
              CURRENCY_SYMBOLS[formik.values.currency]
            }`}
        </Button>
      </div>
    </div>
  );
};

export default BuyMenu;
