import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import { TOAST_OPTIONS } from '../../config';
import { useLoader } from '../../hooks/useLoader';
import useMediaQuery from '../../pages/Landing/components/share/useQuery';
import { useDeleteRecurringMutation, useRemoveSubscriptionMutation } from '../../redux/api/payment';
import { IPermissions } from '../../redux/api/types';
import { ReviewType } from '../../redux/api/types/reviews';
import {
  useCreateTgCodeMutation,
  useGetUserDataQuery,
  useUpdateUserDataMutation,
} from '../../redux/api/user';
import { useAppSelector } from '../../redux/hooks';
import { pathnameSlicer } from '../../utils/base';
import { creteInitialValues, handleFormWithPhone } from '../../utils/formik';
import { useProfileValidationSchema } from '../../utils/validations/profile';
import { Button } from '../../view/Button';
import { Checkbox } from '../../view/Checkbox';
import { FormTextInput } from '../../view/FormTextInput';
import ImageInput from '../../view/ImageInput';
import { ChangePasswordForm } from '../ChangePasswordForm';
import { Loader } from '../Loader';

import CategoryDropdown from './components/CategoryDropdown';
import Navbar from './components/Navbar';
import { adminConfig, config, PERMISSIONS } from './config';

export const ProfileBody = () => {
  const { id, role, subscription } = useAppSelector(state => state.user);
  const { pathname } = useLocation();
  const currentPage = pathnameSlicer(pathname);
  const isMobile = useMediaQuery('(max-width: 1280px) and (max-aspect-ratio: 1024/695)');
  const validationSchema = useProfileValidationSchema();
  const [updateUser, { isSuccess, isError, isLoading: isUpdating }] = useUpdateUserDataMutation();
  const [createCode] = useCreateTgCodeMutation();
  const { data, isLoading, refetch } = useGetUserDataQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });
  const initialValues = useMemo(
    () => creteInitialValues(role === 'admin' ? { ...config, ...adminConfig } : config, data),
    [data, isLoading]
  );

  const [removeReccuringPayment, { isLoading: isReccuringDeleting }] = useDeleteRecurringMutation();
  const [removeSubscription] = useRemoveSubscriptionMutation();

  const { t } = useTranslation();
  const isLoader = useLoader(isLoading, isUpdating);
  const [file, setFile] = useState<File | null>(null);
  const [isRatingEnabled, setIsRatingEnabled] = useState(true);
  const [isComissionEnabled, setIsComissionEnabled] = useState(true);
  const [activeItems, setActiveItems] = useState<ReviewType[]>([]);

  useEffect(() => {
    if (data) {
      setIsRatingEnabled(!!data.isRatingVisible);
      setActiveItems(data.marks);
      setIsComissionEnabled(!!data.isComissionEnabled);
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('back.user.update'), TOAST_OPTIONS);
    }
    if (isError) {
      toast.error(t('back.user.error'), TOAST_OPTIONS);
    }
  }, [isSuccess, isError]);

  const buttonsFormik = useFormik({
    initialValues: {
      button3: {
        ...(data?.button3 || {
          text: '',
          link: '',
          isVisible: false,
        }),
      },
      button4: { ...(data?.button4 || { text: '', link: '', isVisible: false }) },
    },
    onSubmit: () => {},
    enableReinitialize: true,
  });

  const handleSubmitPermissions = (values: Record<string, boolean>) => {
    updateUser({
      permissions: values,
      userId: id,
    })
      .unwrap()
      .then(refetch);
  };

  const permissionsFormik = useFormik({
    initialValues: {
      ...Object.fromEntries(
        PERMISSIONS.map(el => [
          el.key,
          subscription?.permissions?.[el.key as keyof IPermissions] ?? false,
        ])
      ),
    },
    onSubmit: handleSubmitPermissions,
    enableReinitialize: true,
  });

  const handleSubmit = (values: typeof initialValues) => {
    if (file) {
      const formData = new FormData();

      formData.append('file', file as File);
      formData.append('userId', `${id}`);
      formData.append('isRatingVisible', `${isRatingEnabled}`);
      formData.append('isComissionEnabled', `${isComissionEnabled}`);
      formData.append('marks', activeItems.join(','));
      Object.entries(values).forEach(([key, value]) => {
        if (value) {
          formData.append(key, value);
        }
      });

      formData.append('button3', JSON.stringify(buttonsFormik.values.button3));
      formData.append('button4', JSON.stringify(buttonsFormik.values.button4));

      updateUser(formData)
        .unwrap()
        .then(() => refetch());
    } else {
      updateUser({
        ...values,
        userId: id,
        isRatingVisible: `${isRatingEnabled}`,
        isComissionEnabled: `${isComissionEnabled}`,
        marks: activeItems.join(','),
        button3: buttonsFormik.values.button3,
        button4: buttonsFormik.values.button4,
      })
        .unwrap()
        .then(() => refetch());
    }
  };

  const toggleRatingEnabled = (e?: ChangeEvent<HTMLInputElement>) => {
    if (e) {
      setIsRatingEnabled(e.target.checked);
    }
  };

  const toggleComissionEnabled = (e?: ChangeEvent<HTMLInputElement>) => {
    if (e) {
      setIsComissionEnabled(e.target.checked);
    }
  };

  const fileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0] as File);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  return (
    <div
      className={`bg-white py-[1.875vw] px-[2.604vw] flex flex-col gap-[2.083vw]
      md:gap-[3.125vw] md:py-[2.083vw] md:px-[3.255vw] xs:gap-[5.333vw] xs:py-[5.333vw] xs:px-[5.333vw]`}
    >
      {isLoader && <Loader />}
      <Navbar />
      {currentPage === 'profile' ? (
        <div
          className={`p-[2.604vw] flex gap-[1.042vw] md:p-[3.125vw] xs:p-[0] md:gap-[2.083vw] xs:gap-[4.267vw] ${
            isMobile && 'md:flex-col xs:flex-col'
          } bg-white`}
        >
          <ImageInput fileChange={fileChange} size="xl" image={data?.image} />
          <div
            className={`flex flex-col w-[59.115vw] p-[1.302vw] shadow-pass rounded-[0.833vw]
        md:w-full md:p-[3.255vw] md:rounded-[2.083vw]
        xs:p-[6.667vw] xs:rounded-[4.267vw]`}
          >
            {Object.entries(role === 'admin' ? { ...config, ...adminConfig } : config).map(
              ([key, value]) => (
                <FormTextInput
                  key={key}
                  value={formik.values[key]}
                  onChange={handleFormWithPhone(key, formik)}
                  id={key}
                  name={key}
                  inputStyle="font-medium"
                  placeholder={t(value.placeholder) || ''}
                  error={formik.errors[key]}
                  gap="mb-[1.302vw] md:mb-[3.255vw] xs:mb-[6.667vw]"
                  type={value.type}
                />
              )
            )}
            <div className="border-[1px] border-blackBg rounded-2xl p-2 mb-4 flex justify-center flex-col">
              <p className="mb-2 xs:mb-4">{t('profile.button3rd')}</p>
              <div className="flex justify-between items-center gap-4 xs:flex-col xs:gap-1 xs:items-start">
                <FormTextInput
                  value={buttonsFormik.values.button3.text}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    buttonsFormik.setFieldValue('button3.text', e.target.value);
                  }}
                  type="text"
                  wrapperStyle="w-full"
                  placeholder={t('profile.btnText') ?? ''}
                />
                <FormTextInput
                  value={buttonsFormik.values.button3.link}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    buttonsFormik.setFieldValue('button3.link', e.target.value);
                  }}
                  type="text"
                  wrapperStyle="w-full"
                  placeholder={t('profile.btnLink') ?? ''}
                />
                <Checkbox
                  labelText={t('profile.btnVisible') ?? ''}
                  checked={!!buttonsFormik.values.button3.isVisible}
                  toggle={(e?: ChangeEvent<HTMLInputElement>) => {
                    buttonsFormik.setFieldValue('button3.isVisible', e?.target.checked);
                  }}
                  name="3rd-button"
                />
              </div>
            </div>
            <div className="border-[1px] border-blackBg rounded-2xl p-2 mb-4 flex justify-center flex-col">
              <p className="mb-2 xs:mb-4">{t('profile.button4rd')}</p>
              <div className="flex justify-between items-center gap-4 xs:flex-col xs:gap-1 xs:items-start">
                <FormTextInput
                  value={buttonsFormik.values.button4.text}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    buttonsFormik.setFieldValue('button4.text', e.target.value);
                  }}
                  type="text"
                  wrapperStyle="w-full"
                  placeholder={t('profile.btnText') ?? ''}
                />
                <FormTextInput
                  value={buttonsFormik.values.button4.link}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    buttonsFormik.setFieldValue('button4.link', e.target.value);
                  }}
                  type="text"
                  wrapperStyle="w-full"
                  placeholder={t('profile.btnLink') ?? ''}
                />
                <Checkbox
                  labelText={t('profile.btnVisible') ?? ''}
                  checked={!!buttonsFormik.values.button4.isVisible}
                  toggle={(e?: ChangeEvent<HTMLInputElement>) => {
                    buttonsFormik.setFieldValue('button4.isVisible', e?.target.checked);
                  }}
                  name="4rd-button"
                />
              </div>
            </div>
            <div className="w-full flex items-center justify-between mb-[10px]">
              <p>
                Telegram Code: <span>{data?.tgCode}</span>
              </p>
              {!data?.tgCode && (
                <Button
                  onClick={() => {
                    createCode(id).then(refetch);
                  }}
                >
                  {t('profile.tgCode')}
                </Button>
              )}
            </div>
            <div className="flex gap-6 xs:flex-col xs:mb-[20px]">
              <Checkbox
                labelText={t('profile.rating') || ''}
                name="isRatingVisible"
                toggle={toggleRatingEnabled}
                checked={isRatingEnabled}
              />
              {role === 'admin' && (
                <Checkbox
                  labelText={t('profile.comission') || ''}
                  name="isComissionEnabled"
                  toggle={toggleComissionEnabled}
                  checked={isComissionEnabled}
                />
              )}
            </div>
            <CategoryDropdown activeItems={activeItems} setActiveItems={setActiveItems} />
            <div>
              {role === 'admin' && (
                <Button className="mb-2 xs:w-full" onClick={() => removeSubscription(id)}>
                  Remove subscription
                </Button>
              )}
              {data?.recId && (
                <Button
                  disabled={isReccuringDeleting}
                  onClick={() => removeReccuringPayment({ userId: data.id })}
                  className="w-max mb-2"
                >
                  {t('removeReccuring')}
                </Button>
              )}
            </div>
            <Button
              onClick={formik.submitForm}
              className="self-end px-[1.823vw] md:px-[4.557vw] xs:px-[23.733vw] xs:w-full"
            >
              {t('saveBtn')}
            </Button>
          </div>
        </div>
      ) : currentPage === 'permissions' ? (
        <div
          className={`
          bg-white w-[79.167vw] md:w-[83.073vw] xs:w-[89.333vw] p-[1.302vw] md:p-[3.255vw] xs:p-[5.333vw] rounded-[0.834vw] shadow-pass md:rounded-[2.083vw] xs:rounded-[4.267vw]
          flex flex-col gap-4
        `}
        >
          {PERMISSIONS.map(el => (
            <div key={el.key}>
              <Checkbox
                labelText={t(el.label) ?? ''}
                checked={
                  permissionsFormik.values[el.key as keyof typeof permissionsFormik.values] ?? false
                }
                toggle={e => {
                  permissionsFormik.setFieldValue(el.key, e?.target.checked);
                }}
                name={el.key}
              />
            </div>
          ))}
          <Button
            className="self-end px-[1.823vw] md:px-[4.557vw] xs:px-[23.733vw]"
            onClick={permissionsFormik.submitForm}
          >
            {t('saveBtn')}
          </Button>
        </div>
      ) : (
        <ChangePasswordForm isRestoring={false} />
      )}
    </div>
  );
};
