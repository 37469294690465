import { ReviewType } from '../../redux/api/types/reviews';
import { FormikConfig } from '../../types/base';

export const config: FormikConfig = {
  organization: {
    type: 'text',
    placeholder: 'auth.organization',
    required: false,
    initial: '',
  },
  email: {
    type: 'text',
    placeholder: 'auth.email',
    required: false,
    initial: '',
  },
  wish: {
    type: 'text',
    placeholder: 'employee.wish',
    required: false,
    initial: '',
  },
  iban: {
    type: 'text',
    placeholder: 'iban',
    required: false,
    initial: '',
  },
  socialLink: {
    type: 'text',
    placeholder: 'auth.socialLink',
    required: false,
    initial: '',
  },
  phone: {
    type: 'text',
    placeholder: 'auth.phone',
    required: false,
    initial: '',
  },
  uiTopColor: {
    type: 'color',
    placeholder: 'profile.uiTopColor',
    required: false,
    initial: '#FFB800',
  },
  uiBottomColor: {
    type: 'color',
    placeholder: 'profile.uiBottomColor',
    required: false,
    initial: '#ff9900',
  },
  darkBgColor: {
    type: 'color',
    placeholder: 'profile.darkBgColor',
    required: false,
    initial: '#2e2e2e',
  },
  lightBgColor: {
    type: 'color',
    placeholder: 'profile.lightBgColor',
    required: false,
    initial: '#f3f3f3',
  },
};

export const adminConfig: FormikConfig = {
  menuLink: {
    type: 'text',
    placeholder: 'profile.menuLink',
    required: false,
    initial: '',
  },
  qrLink: {
    type: 'text',
    placeholder: 'profile.qrLink',
    required: false,
    initial: '',
  },
  feedbackLink: {
    type: 'text',
    placeholder: 'profile.feedbackLink',
    required: false,
    initial: '',
  },
};

export const dropDownItems: ReviewType[] = [
  'clean',
  'comfort',
  'food',
  'hookah',
  'hospitality',
  'interior',
  'music',
  'organization',
  'politeness',
  'service',
  'team',
  'wine',
];

export const PERMISSIONS = [
  {
    label: 'profile.permissionsInner.menu',
    key: 'menu',
  },
  {
    label: 'profile.permissionsInner.tips',
    key: 'tips',
  },
];
