import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

interface IProps {
  error?: string;
  value: string;
  onChange: (value: string) => void;
}

const Textarea: FC<IProps> = ({ error, value, onChange }) => {
  const [local, setLocal] = useState(value);
  const { t } = useTranslation();

  const handleChange = (val: string) => {
    setLocal(val);
    onChange(val);
  };

  return (
    <div className="w-full bg-white flex flex-col h-max">
      <div
        className={`
            ${
              error
                ? 'mb-[0.313vw] md:mb-[0.781vw] xs:mb-[1.6vw]'
                : 'mb-[1.25vw] md:mb-[3.125vw] xs:mb-[6.4vw]'
            }
          ${error ? 'mb-[0.313vw] md:mb-[0.781vw] xs:mb-[1.6vw]' : ''} w-full rounder-[0.417vw]
            text-authActive focus:border-[1px]
            text-[0.834vw] leading-[1.25vw]
            md:text-[2.083vw] md:leading-[3.125vw]
            xs:text-[4.267vw] xs:leading-[6.4vw]
            focus:outline-0`}
      >
        <ReactQuill
          modules={{
            toolbar: [['bold', 'italic', 'underline', 'strike'], ['link']],
          }}
          theme="snow"
          placeholder={t('foodMenu.item.compound') || ''}
          value={local}
          onChange={handleChange}
        />
      </div>
      {error && (
        <p
          className={`text-[0.625vw] leading-[0.938vw] text-textGray flex items-center gap-[0.417vw]
  md:text-[1.563vw] md:leading-[2.344vw] xs:text-[3.2vw] xs:leading-[4.8vw] md:gap-[0.417vw] xs:gap-[2.133vw]`}
        >
          <img
            src="/images/icons/error.svg"
            alt="error icon"
            className="w-[0.833vw] h-[0.833vw] md:w-[2.083vw] md:h-[2.083vw] xs:w-[4.267vw] xs:h-[4.267vw]"
          />
          {error}
        </p>
      )}
    </div>
  );
};

export default Textarea;
