import { ChangeEvent, FC } from 'react';

interface IProps {
  isChecked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Switch: FC<IProps> = ({ isChecked, onChange }) => {
  return (
    <label className="relative w-[68px] h-[42px] inline-block">
      <input
        className="opacity-0 w-0 h-0"
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
      />
      <span
        className={`
        absolute cursor-pointer top-0 left-0 right-0 bottom-0 transition-all border-[1px] bg-mainOrange border-[#141414] rounded-[40px] before:rounded-full
        before:absolute before:h-[34px] before:w-[34px] before:left-[4px] before:bottom-[3px] before:transition-all
        ${isChecked ? 'before:translate-x-[73%] before:bg-mainGreen' : 'before:bg-mainBlack'}
      `}
      />
    </label>
  );
};

export default Switch;
