import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Roles } from '../../types/base';
import { IPermissions, IUserLoginResponse, SubPeriod } from '../api/types';
import { ISubscription } from '../api/types/user';

interface IInitialState {
  isLogged: boolean;
  token: string;
  id: number;
  role: Roles | '';
  modal: boolean;
  adminId?: number;
  organization: string;
  status: number;
  menuId: number;
  menuLang: string;
  extraPath: string;
  subscription?: {
    permissions: IPermissions;
    isActive: boolean;
    type: SubPeriod;
    startDate: number;
    period: number;
  };
}

const initialState: IInitialState = {
  isLogged: false,
  token: '',
  id: 0,
  role: '',
  organization: '',
  modal: false,
  status: 0,
  menuId: 0,
  menuLang: '',
  extraPath: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginUser: (
      state,
      action: PayloadAction<IUserLoginResponse & { remember?: boolean; lang?: string }>
    ) => {
      const { token, id, role, organization, remember, status, lang } = action.payload;
      localStorage.setItem('tipsToken', token);

      if (!remember) {
        window.addEventListener('beforeunload', () => localStorage.removeItem('tipsToken'));
      }
      if (role === 'admin') {
        return {
          isLogged: true,
          token,
          id,
          role,
          modal: false,
          adminId: id,
          organization: 'admin',
          status,
          menuId: 0,
          menuLang: lang ? lang : '',
          extraPath: '',
        };
      } else {
        return {
          isLogged: true,
          token,
          id,
          role,
          modal: false,
          organization,
          status,
          menuId: 0,
          menuLang: lang ? lang : '',
          extraPath: '',
          subscription: action.payload.subscription,
        };
      }
    },
    logOut: () => {
      localStorage.removeItem('tipsToken');
      return initialState;
    },
    openModal: state => {
      state.modal = true;
    },
    closeModal: state => {
      state.modal = false;
    },
    replaceUser: (state, action: PayloadAction<{ id: number; organization: string }>) => {
      state.id = action.payload.id;
      state.organization = action.payload.organization;
    },
    updateOrganization: (
      state,
      action: PayloadAction<{
        organization: string;
        subscription?: ISubscription;
      }>
    ) => {
      state.organization = action.payload.organization;
      if (action.payload.subscription) {
        state.subscription = { ...action.payload.subscription };
      }
    },
    updateMenuId: (state, action: PayloadAction<number>) => {
      state.menuId = action.payload;
    },
    updateMenuLang: (state, action: PayloadAction<string>) => {
      state.menuLang = action.payload;
    },
    updateExtraPath: (state, action: PayloadAction<string>) => {
      state.extraPath = action.payload;
    },
  },
});

export const {
  loginUser,
  logOut,
  openModal,
  closeModal,
  replaceUser,
  updateOrganization,
  updateMenuId,
  updateMenuLang,
  updateExtraPath,
} = userSlice.actions;
export default userSlice.reducer;
