import {
  ADMIN_DATA,
  CREATE_TG_CODE,
  DELETE_USER,
  GET_ADMIN_TRANSACTIONS,
  GET_EMPLOYEE_DATA,
  GET_EMPLOYYES_CARDS,
  GET_USER,
  PAYMENT_DATA,
  UPDATE_USER,
} from '../../constants/api';
import { updateOrganization } from '../slices/userSlice';

import { IAdminTransactionsResponse } from './types/reviews';
import {
  IAdminDataResponse,
  IEmploeyyCardItem,
  IEmployeeData,
  IPaymentDataResponse,
  IUpdateUserPayload,
  IUserItem,
} from './types/user';
import baseApi from './base';

const userApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    updateUserData: builder.mutation<void, IUpdateUserPayload | FormData>({
      query: body => ({
        url: UPDATE_USER,
        method: 'PUT',
        body,
      }),
    }),
    getUserData: builder.query<IUserItem, number>({
      query: id => ({
        url: `${GET_USER}/${id}`,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(
          updateOrganization({ organization: data.organization, subscription: data.subscription })
        );
      },
    }),
    getUserEmployyes: builder.query<IEmploeyyCardItem[], number>({
      query: id => ({
        url: `${GET_EMPLOYYES_CARDS}/${id}`,
      }),
    }),
    deleteUser: builder.mutation<void, { id: number }>({
      query: body => ({
        url: DELETE_USER,
        method: 'DELETE',
        body,
      }),
    }),
    getAdminData: builder.query<IAdminDataResponse[], string>({
      query: type => ({
        url: `${ADMIN_DATA}/${type}`,
      }),
    }),
    getAdminTransactions: builder.query<IAdminTransactionsResponse, string>({
      query: period => ({
        url: `${GET_ADMIN_TRANSACTIONS}?period=${period}`,
      }),
    }),
    getPaymentData: builder.query<IPaymentDataResponse, string>({
      query: id => ({
        url: `${PAYMENT_DATA}/${id}`,
      }),
    }),
    getEmployeeData: builder.query<IEmployeeData, number>({
      query: id => ({
        url: `${GET_EMPLOYEE_DATA}/${id}`,
      }),
    }),
    createTgCode: builder.mutation<void, number>({
      query: id => ({
        url: `${CREATE_TG_CODE}/${id}`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useUpdateUserDataMutation,
  useGetUserDataQuery,
  useGetUserEmployyesQuery,
  useDeleteUserMutation,
  useGetAdminDataQuery,
  useGetPaymentDataQuery,
  useGetEmployeeDataQuery,
  useGetAdminTransactionsQuery,
  useCreateTgCodeMutation,
} = userApi;
