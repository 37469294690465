import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

import ImageInput from '../../../../../view/ImageInput';

interface IProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  imageFromDB?: string;
  file?: File;
  className?: string;
  description?: string;
  title?: string;
  id?: string;
}

const FileBlock: FC<IProps> = ({
  onChange,
  imageFromDB,
  file,
  className,
  description,
  title,
  id,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`w-full px-[0.729vw] py-[0.625vw] border-[1px] border-inputBorder rounded-[0.417vw]
      xs:rounded-[2.133vw] md:rounded-[1.042vw] xs:px-[3.733vw] xs:py-[3.2vw] md:px-[1.823vw] md:py-[1.563vw]
      ${className}
      `}
    >
      <p className="text-mainBlack text-[0.938vw] leading-[1.563vw] md:text-[2.344vw] md:leading-[3.906vw] xs:text-[4.267vw] xs:leading-[6.4vw]">
        {title || t('qr.promo.imageTitle')}
      </p>
      <p className="text-textGray text-[0.729vw] leading-[1.042vw] mb-[0.938vw] md:text-[1.823vw] md:leading-[2.604vw] xs:text-[3.2vw] xs:leading-[4.8vw] md:mb-[2.344vw] xs:mb-[3.2vw]">
        {description || t('qr.promo.imageDesc')}
      </p>
      <ImageInput
        size="sm"
        file={file}
        fileChange={onChange}
        image={imageFromDB}
        withDesc={false}
        id={id}
        className="md:mt-auto"
      />
    </div>
  );
};

export default FileBlock;
