import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IInitialState {
  isDefaultLangApplied: boolean;
  isDarkMode: boolean;
}

const initialState: IInitialState = {
  isDefaultLangApplied: false,
  isDarkMode: false,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    toggleDefaultLangApplied: (state, action: PayloadAction<boolean>) => {
      state.isDefaultLangApplied = action.payload;
    },
    toggleDarkMode: (state, action: PayloadAction<boolean>) => {
      state.isDarkMode = action.payload;
    },
  },
});

export const { toggleDefaultLangApplied, toggleDarkMode } = settingsSlice.actions;
export default settingsSlice.reducer;
