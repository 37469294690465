import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { MODAL_ANCHOR_ID } from '../../../constants/base';
import { useDeleteMenuItemImageMutation } from '../../../redux/api/menu';
import { IMenuItem, IMenuItemsResponse } from '../../../redux/api/types/menu';
import { ReactComponent as CloseIcon } from '../../../static/close.svg';
import { Allergens } from '../../../types/base';
import { Button } from '../../../view/Button';
import { FormTextInput } from '../../../view/FormTextInput';
import { Select } from '../../../view/Select';
import FileBlock from '../../QRBody/components/Promo/components/FileBlock';
import {
  additionalFields,
  config,
  convertAllergenToSelectItem,
  defaultDishTypes,
  getDefaultTimeTypes,
  KBJU,
  KBJUInputs,
} from '../config';
import { useLocalFormik } from '../hooks/useLocalFormik';

import AllergensDropdown from './AllerensDropdown';
import Textarea from './Textarea';

const trimLastPart = (str: string) => {
  const lastDot = str.lastIndexOf('.');
  return str.slice(0, lastDot + 1);
};

interface IProps {
  onClose: () => void;
  refetch: () => void;
  currentData?: IMenuItem;
  maxOrder?: number;
  categories?: IMenuItemsResponse['categories'];
}

const MenuItemForm: FC<IProps> = ({ onClose, refetch, currentData, maxOrder, categories }) => {
  const { t } = useTranslation();
  const { categoryId } = useParams();

  const [allergens, setAllergens] = useState<
    {
      title: string;
      value: Allergens;
    }[]
  >([]);

  const [deleteImage, { isSuccess }] = useDeleteMenuItemImageMutation();

  useEffect(() => {
    setAllergens(currentData?.allergens.map(convertAllergenToSelectItem) ?? []);
  }, [currentData]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('back.user.update'));
    }
  }, [isSuccess]);

  const [file, setFile] = useState<File>();
  const fileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0] as File);
  };

  const localOnClose = () => {
    onClose();
    setAllergens([]);
  };

  const formik = useLocalFormik(
    localOnClose,
    refetch,
    file,
    currentData,
    maxOrder,
    allergens,
    categoryId
  );

  const handleTypeSelect = (value: string) => {
    if (t('foodMenu.item.type') !== value) {
      formik.setFieldValue('type', value);
    } else {
      formik.setFieldValue('type', '');
    }
  };

  const handleCompoundChange = useCallback((value: string) => {
    formik.setFieldValue('compound', value);
  }, []);

  const handleCategorySelect = (value: string) => {
    formik.setFieldValue('categoryId', value);
  };

  const handleTimeSelect = (value: string) => {
    formik.setFieldValue('time', value);
  };

  const defaultTimes = useMemo(() => getDefaultTimeTypes(), []);

  const deleteHandler = () => {
    deleteImage({ id: currentData?.id ?? -1 });
    setFile(undefined);
  };

  const mappedCategories = useMemo(() => {
    return (
      categories
        ?.filter(el => el.category_translations[0]?.name)
        .map(el => ({ title: el.category_translations[0].name, value: `${el.id}` })) ?? []
    );
  }, [categories]);

  return createPortal(
    <div className="absolute top-0 left-0 bottom-0 right-0 z-50 flex items-center justify-center bg-slate-500/50">
      <div
        className={`bg-white p-3 rounded-xl flex flex-col w-[36vw] px-[30px] py-[40px] relative
        md:w-[65vw] xs:w-[90vw] xs:px-[15px] xs:py-[20px] xs:max-h-[200vw] overflow-auto`}
      >
        <p className="text-[24px] leading-[34px] text-mainBlack font-bold mb-[24px] xs:text-[20px] xs:leading-[30px]">
          {t('foodMenu.category.modalDish')}
        </p>
        {Object.entries(config).map(([key, value]) => (
          <FormTextInput
            type={value.type}
            key={key}
            {...formik.getFieldProps(key)}
            placeholder={t(value.placeholder) as string}
            error={formik.errors[key as keyof typeof formik.initialValues]}
          />
        ))}
        <Select
          options={mappedCategories}
          onChange={handleCategorySelect}
          className="w-full mb-[20px] !shadow-none border-[1px] border-inputBorder xs:w-full md:w-full md:mb-[3.125vw] xs:mb-[6.4vw] font-normal"
          dropdownStyle="border-[1px] border-inputBorder"
          placeholder={t('foodMenu.item.type') || ''}
          initialValue={mappedCategories.find(el => el.value === categoryId)}
        />
        <Select
          options={defaultDishTypes}
          onChange={handleTypeSelect}
          className="w-full mb-[20px] !shadow-none border-[1px] border-inputBorder xs:w-full md:w-full md:mb-[3.125vw] xs:mb-[6.4vw] font-normal"
          dropdownStyle="border-[1px] border-inputBorder"
          placeholder={t('foodMenu.item.type') || ''}
          initialValue={defaultDishTypes.find(el => el.value === currentData?.type)}
        />
        <AllergensDropdown
          activeItems={allergens}
          setActiveItems={setAllergens}
          placeholder={t('foodMenu.item.allergen') ?? ''}
        />
        <Select
          options={defaultTimes}
          onChange={handleTimeSelect}
          className="w-full mb-[20px] !shadow-none border-[1px] border-inputBorder xs:w-full md:w-full md:mb-[3.125vw] xs:mb-[6.4vw] font-normal"
          dropdownStyle="border-[1px] border-inputBorder"
          placeholder={t('foodMenu.item.time') || ''}
          initialValue={defaultTimes.find(el => +el.value.split(' ')[0] === currentData?.time)}
          isSplited={true}
        />
        <div className="w-full">
          <p className="mb-[12px] text-[16px] leading-[20px] text-textGray">
            {t('foodMenu.item.KBJU')}
          </p>
          <div className="flex gap-[14px] xs:flex-wrap xs:gap-0 md:gap-[8px]">
            {KBJU.map((el, i) => (
              <FormTextInput
                type={additionalFields[el].type}
                key={additionalFields[el].placeholder}
                {...formik.getFieldProps(el)}
                placeholder={
                  !formik.getFieldProps(el).value && formik.getFieldProps(el).value !== 0
                    ? (t(additionalFields[el].placeholder) as string)
                    : ''
                }
                error={formik.errors[el as keyof typeof formik.initialValues]}
                placeholderStyle="md:text-[12px]"
                wrapperStyle={`xs:w-full ${KBJUInputs[i]}`}
              />
            ))}
          </div>
        </div>
        <Textarea
          value={formik.getFieldProps('compound').value}
          onChange={handleCompoundChange}
          error={formik.errors.compound}
        />
        <div className="flex gap-[10px] items-end mb-[24px]">
          <FileBlock
            onChange={fileChange}
            file={file}
            imageFromDB={currentData?.image ? currentData.image : undefined}
            description={trimLastPart(t('foodMenu.category.image') as string)}
          />
          <Button onClick={deleteHandler}>{t('foodMenu.deleteBtn')}</Button>
        </div>
        <Button onClick={formik.submitForm}>{t('saveBtn')}</Button>
        <button
          onClick={onClose}
          className="absolute right-[0.642vw] top-[0.642vw] md:right-[2.604vw] md:top-[2.604vw] xs:right-[4.267vw] xs:top-[4.267vw]"
        >
          <CloseIcon
            className="w-[1.25vw] h-[1.25vw] md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]"
            stroke="black"
          />
        </button>
      </div>
    </div>,
    document.getElementById(MODAL_ANCHOR_ID)!
  );
};

export default MenuItemForm;
