import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { API_URL } from '../../../../../constants/api';
import { useGetClientMenuMainQuery } from '../../../../../redux/api/menu';
import { IPermissions } from '../../../../../redux/api/types';
import MenuButton from '../../../shared/Button';

interface IProps {
  image?: string;
  qrLink: string;
  feedbackLink: string;
  menuLink: string;
  permissions?: IPermissions;
}

const ButtonBlock: FC<IProps> = ({ image, qrLink, feedbackLink, menuLink, permissions }) => {
  const { hash } = useParams();
  const { data } = useGetClientMenuMainQuery(hash as string);
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const handleClick = (to: string) => () => {
    if (!menuLink) navigate(to);
  };

  return (
    <div className="flex items-center relative z-10 flex-col">
      <h1
        className={`dark:text-white text-[#4D4D4D] text-[40px] leading-[46px] mb-[40px] font-bold ${
          language !== 'ge' ? 'font-sourceSans' : 'font-helvetica'
        }`}
      >
        {t('foodMenu.client.greating')}
      </h1>
      <div
        style={{
          borderColor: data?.user.uiTopColor ?? '#efb916',
        }}
        className="overflow-hidden rounded-full border-[1px] w-[51.733vw] h-[51.733vw] dark:bg-blackBg bg-[#F3F3F3] flex items-center justify-center mb-[80px]"
      >
        {image ? (
          <img src={`${API_URL}/image/${image}`} />
        ) : (
          <p className="text-[#EAEAEA] dark:text-[#3e3e3e] text-[36px] leading-normal max-w-[76px]">
            your logo
          </p>
        )}
      </div>
      <div className="flex flex-col gap-[12px] w-[78.667vw]">
        <MenuButton
          dark={true}
          icon="restaraunt"
          link={menuLink}
          onClick={handleClick('categories')}
          style={{
            backgroundImage: `linear-gradient(175deg, ${
              data?.user.uiTopColor ?? '#FFB800'
            } 4.17%, ${data?.user.uiBottomColor ?? '#F90'} 95.27%)`,
          }}
        >
          {t('foodMenu.client.menuBtn')}
        </MenuButton>
        {permissions?.tips && (
          <MenuButton
            dark={true}
            icon="payment"
            link={qrLink}
            style={{
              backgroundImage: `linear-gradient(175deg, ${
                data?.user.uiTopColor ?? '#FFB800'
              } 4.17%, ${data?.user.uiBottomColor ?? '#F90'} 95.27%)`,
            }}
          >
            {t('foodMenu.client.tipsBtn')}
          </MenuButton>
        )}
        {data?.user.button3?.isVisible && (
          <MenuButton
            dark={true}
            icon="feedback"
            link={data?.user.button3.link ?? feedbackLink}
            style={{
              backgroundImage: `linear-gradient(175deg, ${
                data?.user.uiTopColor ?? '#FFB800'
              } 4.17%, ${data?.user.uiBottomColor ?? '#F90'} 95.27%)`,
            }}
          >
            {data?.user.button3.text || t('foodMenu.client.feedbackBtn')}
          </MenuButton>
        )}
        {data?.user.button4?.isVisible && (
          <MenuButton
            dark={true}
            icon="feedback"
            link={data?.user.button4.link}
            style={{
              backgroundImage: `linear-gradient(175deg, ${
                data?.user.uiTopColor ?? '#FFB800'
              } 4.17%, ${data?.user.uiBottomColor ?? '#F90'} 95.27%)`,
            }}
          >
            {data?.user.button4.text}
          </MenuButton>
        )}
      </div>
    </div>
  );
};

export default ButtonBlock;
