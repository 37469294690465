import { FC } from 'react';

interface IProps {
  className?: string;
  topColor?: string;
  bottomColor?: string;
}

const Logo: FC<IProps> = ({ className, topColor, bottomColor }) => {
  return (
    <svg
      width="53"
      height="46"
      viewBox="0 0 53 46"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_201_292)">
        <path
          d="M20.8447 2.16397C20.4965 1.84873 19.8632 1.16381 19.836 0.69941C19.8188 0.40413 20.0192 0.114601 20.3116 0.0388361C20.5757 -0.0298256 20.8812 0.0858508 21.0968 0.250571C21.8021 0.789719 22.4374 1.5105 22.8145 2.30028C22.9126 2.50502 23.1449 3.00674 23.5116 3.80542C23.7468 4.31751 24.1018 4.91517 24.6559 5.12995C24.7638 5.1719 24.9226 5.20865 25.1322 5.24022C25.9887 5.36976 27.5456 5.48747 28.2413 4.84922C28.3062 4.78969 28.3998 4.66838 28.5219 4.48528C29.2667 3.36842 30.0173 2.25529 30.7738 1.14588C31.081 0.695689 32.1011 -0.298046 32.638 0.315513C33.0419 0.777204 32.638 1.29809 32.308 1.64173C31.8197 2.15112 28.939 5.16682 29.1587 5.73337C29.1729 5.77012 29.2107 5.80699 29.272 5.84397C29.483 5.97137 29.6812 6.1344 29.8667 6.33306C31.091 7.64237 31.944 9.65757 31.1055 11.3981C30.8492 11.9296 30.5953 12.3998 30.3437 12.8086C30.1818 13.0714 29.9487 13.3873 29.782 13.641C29.7593 13.6755 29.7521 13.7146 29.7603 13.7583C29.7624 13.7694 29.7676 13.7789 29.7758 13.7867C31.0249 14.977 31.8173 16.5856 32.1624 18.246C32.163 18.2492 32.1627 18.2524 32.1616 18.2554C32.1605 18.2584 32.1586 18.261 32.1561 18.263C32.1536 18.2651 32.1506 18.2664 32.1474 18.2668C32.1442 18.2673 32.1409 18.2669 32.1379 18.2657C31.232 17.8918 30.3005 17.6033 29.3436 17.4001C26.5817 16.8143 23.5577 16.9712 20.9349 18.0685C20.9335 18.0691 20.9319 18.0692 20.9304 18.069C20.9288 18.0688 20.9274 18.0681 20.9262 18.0672C20.925 18.0662 20.9241 18.0649 20.9235 18.0635C20.923 18.0621 20.9229 18.0605 20.9232 18.059C21.072 17.3424 21.2831 16.6444 21.5565 15.965C21.9119 15.0822 22.4268 14.3611 23.2092 13.7939C23.2126 13.7914 23.2155 13.7882 23.2175 13.7846C23.2195 13.7809 23.2207 13.7768 23.221 13.7727C23.2213 13.7685 23.2206 13.7643 23.219 13.7605C23.2175 13.7566 23.215 13.7531 23.2119 13.7502C21.271 11.9748 20.636 9.28585 22.4254 7.14212C22.7577 6.74401 23.442 6.1822 23.2874 5.5267C23.2199 5.2409 22.975 4.79578 22.787 4.49204C22.2509 3.62661 21.6035 2.85059 20.8447 2.16397Z"
          fill="url(#paint0_linear_201_292)"
        />
        <path
          d="M18.4946 13.943C19.6431 14.3513 20.8471 15.2601 20.5757 16.6327C20.3226 17.9136 19.0835 19.0856 18.1079 19.8882C15.2733 22.22 11.6204 24.2179 7.7999 24.0177C3.88052 23.8124 1.0294 20.9709 0.120582 17.3727C0.00441179 16.9127 -0.0214167 16.4471 0.0430971 15.9758C0.393331 13.412 3.28543 12.5904 5.45812 12.333C9.17122 11.8926 13.0011 12.3249 16.5826 13.3471C17.379 13.5743 18.0164 13.773 18.4946 13.943Z"
          fill="url(#paint1_linear_201_292)"
        />
        <path
          d="M51.63 20.2582C50.2865 22.3884 47.9685 23.8097 45.417 24.0011C41.4818 24.296 37.7436 22.2386 34.8401 19.8401C33.7735 18.959 32.0492 17.3138 32.4411 15.7925C32.7022 14.7801 33.8276 14.1493 34.753 13.8527C35.2831 13.6829 35.8157 13.521 36.3509 13.367C40.127 12.2792 44.2148 11.8358 48.1163 12.4152C50.0834 12.7074 52.4313 13.5598 52.9145 15.7099C53.0362 16.2516 53.0192 16.8177 52.8635 17.4082C52.5935 18.4326 52.1824 19.3826 51.63 20.2582Z"
          fill="url(#paint2_linear_201_292)"
        />
        <path
          d="M19.9468 35.309C19.3386 34.7756 18.7945 34.1962 18.4229 33.4937C18.0845 32.8549 17.8092 32.1447 17.5971 31.3632C16.6948 28.0407 16.7155 24.0681 18.7032 21.0923C19.3878 20.0675 20.2653 19.2158 21.3866 18.7226C22.3316 18.307 23.3424 18.0594 24.3676 17.9329C25.9852 17.7331 27.5934 17.789 29.192 18.1006C30.5058 18.3567 31.8482 18.8373 32.892 19.6907C34.699 21.1681 35.9522 23.4167 34.7162 25.6964C34.5295 26.0404 34.3139 26.3756 34.2068 26.7547C34.0433 27.3334 34.4906 27.7853 34.8419 28.1753C35.9639 29.421 36.1546 31.1051 35.4983 32.615C34.3742 35.1998 31.5124 36.5274 28.8497 36.9146C27.9766 37.0416 27.0985 37.096 26.2155 37.078C24.0042 37.0323 21.6731 36.824 19.9468 35.309ZM23.7105 25.5706C25.7857 25.2597 27.8616 25.2205 29.9568 25.4867C31.1184 25.6345 31.7352 25.1789 31.6584 23.9781C31.6178 23.3432 31.3987 22.8007 31.001 22.3509C29.8029 20.9956 27.7359 20.4838 25.9817 20.6881C24.4513 20.866 23.0734 21.6 21.9641 22.6296C20.9517 23.5699 20.4086 24.822 20.2653 26.1709C20.2651 26.1735 20.2654 26.1762 20.2664 26.1786C20.2673 26.1811 20.2689 26.1833 20.2708 26.185C20.2728 26.1868 20.2752 26.1881 20.2777 26.1888C20.2803 26.1896 20.283 26.1897 20.2856 26.1892C21.4266 25.97 22.563 25.7424 23.7105 25.5706ZM22.2018 32.8277C23.1343 33.7325 24.4843 34.1401 25.7596 34.2365C27.5118 34.3691 29.5594 34.0413 31.0571 33.0824C32.4013 32.222 32.7907 30.3015 31.2862 29.3348C31.0196 29.1636 30.71 29.0733 30.3574 29.0639C29.5942 29.0436 28.8173 29.1927 28.0648 29.3409C26.5069 29.6476 25.1424 29.9054 23.674 29.9477C22.5458 29.9798 21.3821 29.8141 20.3442 29.3497C20.3421 29.3488 20.3398 29.3485 20.3376 29.3488C20.3353 29.3492 20.3332 29.3502 20.3315 29.3517C20.3299 29.3532 20.3287 29.3551 20.3281 29.3573C20.3275 29.3595 20.3276 29.3617 20.3283 29.3639C20.7571 30.5436 21.2757 31.9294 22.2018 32.8277Z"
          fill="url(#paint3_linear_201_292)"
        />
        <path
          d="M26.0703 42.0869C23.9362 41.5139 22.4051 39.1094 21.6389 37.2302C21.6382 37.2284 21.638 37.2264 21.6383 37.2246C21.6387 37.2227 21.6395 37.2209 21.6408 37.2195C21.642 37.2181 21.6436 37.217 21.6454 37.2165C21.6472 37.2159 21.6491 37.2158 21.6509 37.2163C23.9304 37.7355 26.3482 37.9432 28.6776 37.6678C29.7817 37.5376 30.8689 37.2694 31.88 36.8094C31.8961 36.8022 31.9021 36.807 31.8979 36.8239C31.2949 39.3509 29.4232 42.9873 26.0703 42.0869Z"
          fill="url(#paint4_linear_201_292)"
        />
        <path
          d="M26.2512 42.852L28.0657 42.8435C28.0799 42.8435 28.0853 42.8503 28.0819 42.8638C27.827 43.8961 27.5464 44.9335 27.3363 45.9745C27.3359 45.9769 27.3348 45.979 27.3332 45.9807C27.3315 45.9825 27.3294 45.9836 27.327 45.9842C27.3246 45.9847 27.3221 45.9846 27.3198 45.9838C27.3175 45.983 27.3154 45.9815 27.3139 45.9796C27.1989 45.8342 27.1114 45.684 27.0367 45.5037C26.6964 44.6828 26.464 43.7554 26.2315 42.877C26.2272 42.8605 26.2337 42.8522 26.2512 42.852Z"
          fill="url(#paint5_linear_201_292)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_201_292"
          x1="23.783"
          y1="0.0186732"
          x2="33.0546"
          y2="1.74393"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={topColor ?? '#F5B40A'} />
          <stop offset="1" stopColor={bottomColor ?? '#F9C43D'} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_201_292"
          x1="6.2799"
          y1="12.1615"
          x2="18.9095"
          y2="17.9005"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={topColor ?? '#F5B40A'} />
          <stop offset="1" stopColor={bottomColor ?? '#F9C43D'} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_201_292"
          x1="38.657"
          y1="12.1597"
          x2="51.2882"
          y2="17.9009"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={topColor ?? '#F5B40A'} />
          <stop offset="1" stopColor={bottomColor ?? '#F9C43D'} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_201_292"
          x1="22.7614"
          y1="17.8158"
          x2="35.8428"
          y2="21.1663"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={topColor ?? '#F5B40A'} />
          <stop offset="1" stopColor={bottomColor ?? '#F9C43D'} />
        </linearGradient>
        <linearGradient
          id="paint4_linear_201_292"
          x1="24.7611"
          y1="36.8062"
          x2="30.8532"
          y2="39.8247"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={topColor ?? '#F5B40A'} />
          <stop offset="1" stopColor={bottomColor ?? '#F9C43D'} />
        </linearGradient>
        <linearGradient
          id="paint5_linear_201_292"
          x1="26.7941"
          y1="42.8435"
          x2="28.1322"
          y2="43.0501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={topColor ?? '#F5B40A'} />
          <stop offset="1" stopColor={bottomColor ?? '#F9C43D'} />
        </linearGradient>
        <clipPath id="clip0_201_292">
          <rect width="53" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
