import { Roles } from '../../../types/base';

export interface IUserRestorePasswordResponse {
  id: number;
  email: string;
}

export interface IRegisterPayload {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  organization?: string;
  role: Roles;
  iban?: string;
  thirdname?: string;
  proffession?: string;
  socialLink: string;
  phone: string;
}

export interface IUserLoginPayload {
  email: string;
  password: string;
  remember?: boolean;
}

export interface IPermissions {
  menu: boolean;
  tips: boolean;
}

export interface IUserLoginResponse {
  token: string;
  id: number;
  role: Roles;
  organization: string;
  status: number;
  subscription: {
    permissions: IPermissions;
    isActive: boolean;
    type: SubPeriod;
    startDate: number;
    period: number;
  };
}

export interface IRestorePasswordEmailPayload {
  email: string;
}

export interface IChangePaswordPayload {
  currentPassword?: string;
  newPassword: string;
  userId?: number;
  isRestoring: boolean;
}

export interface IGetRequestWithLangAndId {
  id: number;
  lang: string;
}

export enum SubPeriod {
  MONTH = 'month',
  MONTH_3 = 'month_3',
  MONTH_6 = 'month_6',
  YEAR = 'year',
  TRIAL = 'trial',
}
