import {
  AFTER_PAY_DATA,
  CREATE_RECURRING,
  DELETE_RECURRING,
  DELETE_SUBSCRIPTION,
  PAYMENT_CREATE,
  PAYMENT_CREATE_TBC,
  SEND_AFTER_PAY_DATA,
} from '../../constants/api';

import {
  ICreatePaymentPayload,
  ICreateRecurringPayment,
  IDeleteRecurringPayment,
  IPaymentResponse,
  IRedirectDataResponse,
  ITBCPaymentResponse,
} from './types/payment';
import baseApi from './base';

const paymentApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    createPayment: builder.mutation<IPaymentResponse, ICreatePaymentPayload>({
      query: data => ({
        url: PAYMENT_CREATE,
        method: 'POST',
        body: data,
      }),
    }),
    createTBCPayment: builder.mutation<ITBCPaymentResponse, ICreatePaymentPayload>({
      query: data => ({
        url: PAYMENT_CREATE_TBC,
        method: 'POST',
        body: data,
      }),
    }),
    getAfterPayData: builder.query<IRedirectDataResponse, string>({
      query: id => ({
        url: `${AFTER_PAY_DATA}/${id}`,
      }),
    }),
    sendPromoData: builder.mutation<unknown, FormData>({
      query: data => ({
        url: SEND_AFTER_PAY_DATA,
        method: 'POST',
        body: data,
      }),
    }),
    createRecurring: builder.mutation<string, ICreateRecurringPayment>({
      query: data => ({
        url: CREATE_RECURRING,
        method: 'POST',
        body: data,
      }),
    }),
    deleteRecurring: builder.mutation<string, IDeleteRecurringPayment>({
      query: data => ({
        url: DELETE_RECURRING,
        method: 'POST',
        body: data,
      }),
    }),
    removeSubscription: builder.mutation<void, number>({
      query: data => ({
        url: DELETE_SUBSCRIPTION,
        method: 'POST',
        body: {
          userId: data,
        },
      }),
    }),
  }),
});

export const {
  useCreatePaymentMutation,
  useCreateTBCPaymentMutation,
  useGetAfterPayDataQuery,
  useSendPromoDataMutation,
  useCreateRecurringMutation,
  useDeleteRecurringMutation,
  useRemoveSubscriptionMutation,
} = paymentApi;
