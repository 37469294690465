import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { FormikHelpers, useFormik } from 'formik';

import { ROUTES } from '../../constants/routes';
import { useRegisterUserMutation } from '../../redux/api/auth';
import { IRegisterPayload } from '../../redux/api/types';
import { useAppSelector } from '../../redux/hooks';
import { IErrorResponse } from '../../types/errors';
import { creteInitialValues, handleFormWithPhone } from '../../utils/formik';
import { useRegisterValidationSchema } from '../../utils/validations/register';
import { Button } from '../../view/Button';
import { Checkbox } from '../../view/Checkbox';
import { FormTextInput } from '../../view/FormTextInput';

import { config } from './config';

export const RegisterForm = () => {
  const [registerUser, { isError, error, isLoading, isSuccess }] = useRegisterUserMutation();
  const { id } = useAppSelector(state => state.user);
  // useRedirect(false, isLoading, isSuccess, ROUTES.REVIEW);
  const navigate = useNavigate();
  const [agree, setAgree] = useState(false);
  const { t } = useTranslation();

  const initialValues = useMemo(() => creteInitialValues(config), []);
  const handleFormSubmit = (
    values: typeof initialValues,
    helpers: FormikHelpers<{
      [k: string]: string;
    }>
  ) => {
    const {
      firstName,
      lastName,
      email,
      password,
      organization,
      confirmPassword,
      socialLink,
      phone,
    } = values;
    if (password === confirmPassword) {
      const registerPayload: IRegisterPayload = {
        firstName,
        lastName,
        email,
        password,
        organization,
        role: 'manager',
        socialLink,
        phone,
      };

      registerUser(registerPayload);
    } else {
      helpers.setErrors({ confirmPassword: t('errors.pass.same') || '' });
    }
  };
  const validationSchema = useRegisterValidationSchema();

  const formik = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
    validationSchema,
  });

  useEffect(() => {
    if (error) {
      const { status, data } = error as IErrorResponse;
      if (status === 409) {
        formik.setErrors({ email: data.msg });
      }
    }
  }, [isError]);

  useEffect(() => {
    if (!isLoading && isSuccess && id) {
      navigate(`${ROUTES.DASHBOARD}/${id}${ROUTES.PROFILE}${ROUTES.PROFILE}`);
    }
  }, [isLoading, isSuccess, id]);

  const toggleCheckbox = () => setAgree(prev => !prev);

  return (
    <div
      className={`bg-white w-[35.104vw] px-[2.084vw] pt-[1.25vw] pb-[2.084vw]
        rounded-[0.834vw] border-authBorder flex flex-col shadow-auth border-[1px]
        md:w-[87.76vw] md:px-[5.208vw] md:pt-[3.125vw] md:pb-[5.208vw] md:rounded-[2.083vw]
        xs:w-[89.333vw] xs:px-[5.333vw] xs:pt-[6.4vw] xs:pb-[10.667vw] xs:rounded-[4.267vw]`}
    >
      <div className="flex items-center gap-[1.667vw] mb-[1.25vw] md:gap-[4.167vw] md:mb-[3.125vw] xs:gap-[8.533vw] xs:mb-[6.4vw]">
        <NavLink
          className={({ isActive }) =>
            `text-[1.25vw] text-bold leading-[1.771vw] text-center py-[0.677vw]
            md:text-[3.125vw] md:leading-[4.427vw] md:py-[1.623vw] xs:text-[4.8vw] xs:leading-[7.467vw] xs:py-[3.467vw] ${
              isActive
                ? 'border-b-2 border-mainOrange rounded-[1px] text-authActive'
                : 'text-authInactive'
            }`
          }
          to={ROUTES.LOGIN}
        >
          {t('auth.auth_link')}
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `text-[1.25vw] text-bold leading-[1.771vw] text-center py-[0.677vw]
            md:text-[3.125vw] md:leading-[4.427vw] md:py-[1.623vw] xs:text-[4.8vw] xs:leading-[7.467vw] xs:py-[3.467vw] ${
              isActive
                ? 'border-b-2 border-mainOrange rounded-[1px] text-authActive'
                : 'text-authInactive'
            }`
          }
          to={ROUTES.REGISTER}
        >
          {t('auth.register_link')}
        </NavLink>
      </div>
      <div>
        {Object.entries(config).map(([key, value]) => (
          <FormTextInput
            value={formik.values[key]}
            onChange={handleFormWithPhone(key, formik)}
            key={key}
            placeholder={t(value.placeholder) || ''}
            id={key}
            name={key}
            error={formik.errors[key]}
            required={value.required}
            type={value.type}
          />
        ))}
      </div>
      <Button
        onClick={formik.submitForm}
        className="mb-[1.25vw] md:mb-[3.125vw] xs:mb-[6.4vw]"
        disabled={!agree}
      >
        {t('auth.register')}
      </Button>
      <Checkbox
        labelText={t('auth.agreement') || ''}
        checked={agree}
        toggle={toggleCheckbox}
        classNames="items-start"
        labelClassnames="xs:text-[3.2vw] xs:leading-[4.8vw]"
        name="policy"
      />
    </div>
  );
};
