import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from '@reduxjs/toolkit/dist/query';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';

import { IPermissions, SubPeriod } from '../redux/api/types';

interface FormikConfigItem {
  type: 'text' | 'email' | 'password' | 'number' | 'color';
  placeholder: string;
  required: boolean;
  initial: string;
  disabled?: boolean;
}

export type FormikConfig = Record<string, FormikConfigItem>;

export type Roles = 'admin' | 'manager' | 'employee';
export type ReviewKeyType = 'total' | 'averageRating' | 'transactions';

export interface JWTUser {
  id: number;
  exp: number;
  iat: number;
  role: Roles;
  status: number;
  organization: string;
  subscription: {
    permissions: IPermissions;
    isActive: boolean;
    type: SubPeriod;
    startDate: number;
    period: number;
  };
}

export interface IMenuTableData {
  order: number;
  id: number;
  name: string;
  createdAt: string;
  image: string | null;
  visible: boolean;
}

export type OrderUpdateFn = MutationTrigger<
  MutationDefinition<
    FormData,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta>,
    never,
    { data: { name: string } } | void,
    'api'
  >
>;

export enum DishTypes {
  VEGAN = 'vegan',
  VEGETARIAN = 'vegetarian',
  POST = 'lenten',
  DIET = 'dietary',
  HOT = 'hot',
  KOSHER = 'kosher',
  HALAL = 'halal',
}

export enum Allergens {
  GLUTEN = 'gluten',
  LUPINE = 'lupine',
  CELERY = 'celery',
  CRUSTACEAN = 'crustacean',
  COW_MILK = 'cow-milk',
  SULFITE = 'sulfite',
  SESAME = 'sesame',
  SHELLFISH = 'shellfish',
  MUSTARD = 'mustard',
  NUTS = 'nuts',
  EGGS = 'eggs',
  FISH = 'fish',
  SOUBEANS = 'soybeans',
  PEANUT = 'peanut',
  HONEY = 'honey',
}
