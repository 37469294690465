import {
  CREATE_EMPTY_MENU,
  CREATE_MENU_CATEGORY,
  CREATE_MENU_ITEM,
  DELETE_MENU_CATEGORY,
  DELETE_MENU_ITEM,
  GET_CLIENT_MENU_CATEGORY,
  GET_CLIENT_MENU_ITEMS,
  GET_CURRENT_MENU,
  GET_MENU_CATEGORY_ITEMS,
  GET_USER_MENU_MAIN,
  UPDATE_MENU_ACTIVE_LANGUAGE,
  UPDATE_MENU_BG_IMAGE,
  UPDATE_MENU_CATEGORY,
  UPDATE_MENU_DEFAULT_LANGUAGE,
  UPDATE_MENU_IMAGE,
  UPDATE_MENU_ITEM,
  UPDATE_MENU_LANGUAGE,
} from '../../constants/api';
import { updateMenuId } from '../slices/userSlice';

import {
  IClientMenuCategoriesResponse,
  IClientMenuItemsResponse,
  IClientMenuMainResponse,
  IMenuItemsResponse,
  IMenuResponse,
  IUpdateLanguagePayload,
} from './types/menu';
import baseApi from './base';
import { IGetRequestWithLangAndId } from './types';

const menuApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getMenu: builder.query<IMenuResponse, IGetRequestWithLangAndId>({
      query: ({ id, lang }) => ({
        url: `${GET_CURRENT_MENU}/${id}?lang=${lang}`,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        if (data?.id) {
          dispatch(updateMenuId(data.id));
        }
      },
    }),
    createMenu: builder.mutation<{ menuId: number }, number>({
      query: id => ({
        url: `${CREATE_EMPTY_MENU}/${id}`,
        method: 'POST',
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(updateMenuId(data.menuId));
      },
    }),
    createCategory: builder.mutation<void, FormData>({
      query: body => ({
        url: CREATE_MENU_CATEGORY,
        method: 'POST',
        body,
      }),
    }),
    updateCategory: builder.mutation<{ data: { name: string } }, FormData>({
      query: body => ({
        url: UPDATE_MENU_CATEGORY,
        method: 'PUT',
        body,
      }),
    }),
    addMenuLanguage: builder.mutation<void, IUpdateLanguagePayload>({
      query: body => ({
        url: UPDATE_MENU_LANGUAGE,
        method: 'PUT',
        body,
      }),
    }),
    updateMenuActiveLanguages: builder.mutation<void, FormData>({
      query: body => ({
        url: UPDATE_MENU_ACTIVE_LANGUAGE,
        method: 'PUT',
        body,
      }),
    }),
    getMenuCategoryItems: builder.query<IMenuItemsResponse, IGetRequestWithLangAndId>({
      query: ({ id, lang }) => ({
        url: `${GET_MENU_CATEGORY_ITEMS}/${id}?lang=${lang}`,
      }),
    }),
    createMenuItem: builder.mutation<void, FormData>({
      query: body => ({
        url: CREATE_MENU_ITEM,
        method: 'POST',
        body,
      }),
    }),
    updateMenuItem: builder.mutation<void, FormData>({
      query: body => ({
        url: CREATE_MENU_ITEM,
        method: 'PUT',
        body,
      }),
    }),
    deleteCategory: builder.mutation<void, number>({
      query: id => ({
        url: `${DELETE_MENU_CATEGORY}/${id}`,
        method: 'DELETE',
      }),
    }),
    deleteMenuItem: builder.mutation<void, number>({
      query: id => ({
        url: `${DELETE_MENU_ITEM}/${id}`,
        method: 'DELETE',
      }),
    }),
    getClientMenuMain: builder.query<IClientMenuMainResponse, string>({
      query: hash => ({
        url: `${GET_USER_MENU_MAIN}/${hash}`,
      }),
    }),
    getClientMenuCategory: builder.query<
      IClientMenuCategoriesResponse,
      { lang: string; hash: string }
    >({
      query: ({ lang, hash }) => ({
        url: `${GET_CLIENT_MENU_CATEGORY}/${hash}?lang=${lang}`,
      }),
    }),
    getClientMenuItems: builder.query<IClientMenuItemsResponse, { lang: string; hash: string }>({
      query: ({ lang, hash }) => ({
        url: `${GET_CLIENT_MENU_ITEMS}/${hash}/category?lang=${lang}`,
      }),
    }),
    updateMenuImage: builder.mutation<void, { body: FormData; id: number }>({
      query: body => ({
        url: `${UPDATE_MENU_IMAGE}/${body.id}`,
        method: 'PATCH',
        body: body.body,
      }),
    }),
    updateMenuBgImage: builder.mutation<void, { body: FormData; id: number }>({
      query: body => ({
        url: `${UPDATE_MENU_BG_IMAGE}/${body.id}`,
        method: 'PATCH',
        body: body.body,
      }),
    }),
    deleteMenuImage: builder.mutation<void, { id: number; field?: string }>({
      query: body => ({
        url: `${UPDATE_MENU_IMAGE}/image/${body.id}?field=${body.field}`,
        method: 'DELETE',
      }),
    }),
    deleteMenuItemImage: builder.mutation<void, { id: number }>({
      query: body => ({
        url: `${UPDATE_MENU_ITEM}/${body.id}`,
        method: 'DELETE',
      }),
    }),
    deleteCategoryImage: builder.mutation<void, { id: number }>({
      query: body => ({
        url: `${UPDATE_MENU_CATEGORY}/image/${body.id}`,
        method: 'DELETE',
      }),
    }),
    updateMenuDefaultLanguage: builder.mutation<void, { menuId: number; lang: string }>({
      query: body => ({
        url: UPDATE_MENU_DEFAULT_LANGUAGE,
        method: 'PUT',
        body,
      }),
    }),
    uploadMenuPdf: builder.mutation<void, { menuId: number; lang: string; file: FormData }>({
      query: ({ menuId, lang, file }) => ({
        url: `menu/pdf/${menuId}?lang=${lang}`,
        method: 'POST',
        body: file,
      }),
    }),
    deleteMenuPdf: builder.mutation<void, { menuId: number; lang: string }>({
      query: ({ menuId, lang }) => ({
        url: `menu/pdf/${menuId}?lang=${lang}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetMenuQuery,
  useCreateMenuMutation,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useGetMenuCategoryItemsQuery,
  useAddMenuLanguageMutation,
  useCreateMenuItemMutation,
  useUpdateMenuItemMutation,
  useDeleteCategoryMutation,
  useDeleteMenuItemMutation,
  useUpdateMenuActiveLanguagesMutation,
  useGetClientMenuMainQuery,
  useGetClientMenuCategoryQuery,
  useGetClientMenuItemsQuery,
  useUpdateMenuImageMutation,
  useDeleteMenuImageMutation,
  useDeleteMenuItemImageMutation,
  useDeleteCategoryImageMutation,
  useUpdateMenuDefaultLanguageMutation,
  useUpdateMenuBgImageMutation,
  useUploadMenuPdfMutation,
  useDeleteMenuPdfMutation,
} = menuApi;
