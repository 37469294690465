import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useGetClientMenuMainQuery } from '../../../../redux/api/menu';
import { useDarkTheme } from '../hooks/useDarkTheme';

interface IProps {
  onChange?: (next: boolean) => void;
}

const Switch: FC<IProps> = ({ onChange }) => {
  const [isChecked, setIsChecked] = useDarkTheme();
  const { hash } = useParams();
  const { data } = useGetClientMenuMainQuery(hash as string);

  const handleCheckboxChange = () => {
    setIsChecked(prev => !prev);
    onChange?.(!isChecked);
  };

  return (
    <label className="relative w-[68px] h-[42px] inline-block">
      <input
        className="opacity-0 w-0 h-0"
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <span
        className={`
        absolute cursor-pointer top-0 left-0 right-0 bottom-0 transition-all dark:border-none border-[1px] border-[#D8D8D8] rounded-[40px] before:rounded-full
        before:absolute before:h-[34px] before:w-[34px] before:left-[4px] before:dark:bottom-[4px] before:bottom-[3px] before:bg-white before:dark:bg-none before:dark:bg-bgNeutral20 before:transition-all
        ${isChecked ? 'before:dark:translate-x-[80%] before:translate-x-[73%]' : ''}
      `}
        style={{
          backgroundImage: `linear-gradient(96deg, ${data?.user.uiTopColor ?? '#FFC121'} 5.09%, ${
            data?.user.uiTopColor ?? '#FFA400'
          } 63.02%, ${data?.user.uiBottomColor ?? '#F90'} 97.77%)`,
        }}
      />
    </label>
  );
};

export default Switch;
