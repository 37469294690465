import { SubPeriod } from '../redux/api/types';

export const LANGUAGES = ['en', 'ge', 'ru'];
export const MODAL_ANCHOR_ID = 'modal_anchor';

export const PAYZE_FEE = 2.7;
export const COMPENSATE_FEE_PERCENT = 95;
export const COMPENSATE_FEE_PERCENT_V3 = 96.3;
export const OWN_PERCENT_V3 = 0.037;

export enum Currency {
  GEL = 'GEL',
  USD = 'USD',
  EUR = 'EUR',
}

export const SUBSCRIPTION_PRICES = {
  [SubPeriod.TRIAL]: {
    [Currency.EUR]: 0,
    [Currency.USD]: 0,
    [Currency.GEL]: 0,
  },
  [SubPeriod.MONTH]: {
    [Currency.EUR]: 23,
    [Currency.USD]: 25,
    [Currency.GEL]: 70,
  },
  [SubPeriod.MONTH_3]: {
    [Currency.EUR]: 66,
    [Currency.USD]: 70,
    [Currency.GEL]: 200,
  },
  [SubPeriod.MONTH_6]: {
    [Currency.EUR]: 120,
    [Currency.USD]: 130,
    [Currency.GEL]: 380,
  },
  [SubPeriod.YEAR]: {
    [Currency.EUR]: 220,
    [Currency.USD]: 240,
    [Currency.GEL]: 700,
  },
};
