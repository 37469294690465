import { FC } from 'react';
import { Link } from 'react-router-dom';

import { API_URL } from '../../../../../constants/api';

interface IProps {
  name: string;
  image: string | null;
  id: number;
}

const CategoryCard: FC<IProps> = ({ name, image, id }) => {
  return (
    <Link to={`menu/#${id}`}>
      <div className="dark:border-[1px] rounded-[16px] p-[20px] border-[#494949] bg-categoryCardBg w-full h-[44vw] min-h-max dark:shadow-categoryCardShadow flex items-center justify-center flex-col gap-[13px] shadow-categoryCardShadowLight">
        <img
          src={image ? `${API_URL}/image/${image}` : '/images/menu/category-placeholder.svg'}
          className={`${image && 'h-[67px] w-[66px]'}`}
        />
        <p className="text-[20px] leading-[23px] font-[600] text-white text-ellipsis overflow-hidden text-center max-w-full">
          {name}
        </p>
      </div>
    </Link>
  );
};

export default CategoryCard;
