import { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { TOAST_OPTIONS } from '../../config';
import { API_URL } from '../../constants/api';
import { MODAL_ANCHOR_ID } from '../../constants/base';
import { useClickOutside } from '../../hooks/useClickOutside';
import useMediaQuery from '../../pages/Landing/components/share/useQuery';
import { useAppSelector } from '../../redux/hooks';
import { Button } from '../../view/Button';

import 'react-datepicker/dist/react-datepicker.css';

export const PDFButton = ({ isAdmin }: { period?: string; isAdmin?: boolean }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isDateSelectOpen, setIsDateSelectOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => setIsDateSelectOpen(false));
  const { id, adminId } = useAppSelector(state => state.user);

  const clickHandler = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem('tipsToken');
      const query = new URLSearchParams();
      const dates = [];

      if (startDate) {
        dates.push(startDate.getTime());
      }
      if (endDate) {
        if (!dates.length) {
          dates.push(null);
        }
        dates.push(endDate.getTime());
      }

      query.set('period', dates.length ? dates.join(',') : 'all');

      const resp = await fetch(`${API_URL}/pdf/${isAdmin ? adminId : id}?${query.toString()}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (resp.status !== 200) {
        throw new Error('', { cause: resp.status });
      }

      const blob = await resp.blob();

      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute('download', 'summary.pdf');
      link.click();
    } catch (error) {
      if ((error as { cause: number }).cause === 429) {
        toast.error('Too many requests', TOAST_OPTIONS);
      } else {
        toast.error('Forbidden', TOAST_OPTIONS);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <button
        className={`bg-white text-mainBlack font-bold text-[1.042vw] leading-[1.563vw] flex items-center gap-[0.521vw]
      rounded-[0.417vw] border-[1px] border-phoneOrange px-[2.656vw] py-[0.469vw] normal-case hover:bg-white hover:border-phoneOrange
      md:rounded-[1.042vw] md:px-[2.995vw] md:py-[1.172vw] md:text-[2.604vw] md:leading-[3.906vw] md:gap-[1.302vw]
      xs:rounded-[2.133vw] xs:px-[6vw] xs:py-[2.133vw] disabled:bg-slate-100
      `}
        onClick={() => setIsDateSelectOpen(true)}
        disabled={isLoading}
      >
        {!isMobile && t('analytic.pdf')}
        <img
          src="/images/icons/upload.svg"
          alt="upload icon"
          className="w-[1.25vw] h-[1.25vw] md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]"
        />
      </button>
      {isDateSelectOpen &&
        createPortal(
          <div className="absolute top-0 left-0 bottom-0 right-0 z-20 flex items-center justify-center bg-slate-500/50">
            <div
              className={`bg-white p-3 rounded-xl flex flex-col w-[36vw] px-[30px] py-[40px] relative
        md:w-[65vw] xs:w-[90vw]`}
              ref={ref}
            >
              <p className="text-[24px] leading-[34px] text-mainBlack font-bold mb-[24px] xs:text-[20px] xs:leading-[30px]">
                {t('analytic.dateSelect')}
              </p>
              <div className="md:flex-col flex gap-2 items-center">
                <DatePicker
                  selected={startDate}
                  onChange={date => {
                    if (date) setStartDate(date);
                  }}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  wrapperClassName="border-[1px] border-black border-solid w-full rounded-xl overflow-hidden"
                  className="w-full pl-2 !border-none !outline-none"
                  placeholderText="Start date"
                />
                <DatePicker
                  selected={endDate}
                  onChange={date => {
                    if (date) setEndDate(date);
                  }}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  wrapperClassName="border-[1px] border-black border-solid w-full rounded-xl overflow-hidden"
                  className="w-full pl-2 !border-none !outline-none"
                  placeholderText="End date"
                />
              </div>
              <Button className="mt-[10px]" onClick={clickHandler}>
                {t('analytic.pdfBtn')}
              </Button>
            </div>
          </div>,
          document.getElementById(MODAL_ANCHOR_ID)!
        )}
    </>
  );
};
