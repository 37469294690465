import {
  CHANGE_PASSWORD,
  CONFIRM_REGISTRATION,
  LOGIN,
  REGISTER,
  RESET_PASSWORD,
  RESTORE_PASS_EMAIL,
} from '../../constants/api';
import { loginUser } from '../slices/userSlice';

import baseApi from './base';
import {
  IChangePaswordPayload,
  IRegisterPayload,
  IRestorePasswordEmailPayload,
  IUserLoginPayload,
  IUserLoginResponse,
  IUserRestorePasswordResponse,
} from './types';

const authApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    confirmRegistration: builder.query<IUserLoginResponse, string>({
      query: id => ({
        url: `${CONFIRM_REGISTRATION}${id}`,
      }),
    }),
    resetPassword: builder.query<IUserRestorePasswordResponse, string>({
      query: id => ({
        url: `${RESET_PASSWORD}${id}`,
      }),
    }),
    registerUser: builder.mutation<IUserLoginResponse, IRegisterPayload | FormData>({
      query: body => ({
        url: REGISTER,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(loginUser({ ...data, remember: true }));
      },
    }),
    loginUser: builder.mutation<IUserLoginResponse, IUserLoginPayload>({
      query: body => ({
        url: LOGIN,
        method: 'POST',
        body: {
          email: body.email,
          password: body.password,
        },
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(loginUser({ ...data, remember: arg.remember }));
      },
    }),
    sendRestorePassEmail: builder.mutation<void, IRestorePasswordEmailPayload>({
      query: body => ({
        url: RESTORE_PASS_EMAIL,
        method: 'POST',
        body,
      }),
    }),
    changePassword: builder.mutation<void, IChangePaswordPayload>({
      query: body => ({
        url: body.isRestoring ? RESTORE_PASS_EMAIL : CHANGE_PASSWORD,
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const {
  useConfirmRegistrationQuery,
  useResetPasswordQuery,
  useRegisterUserMutation,
  useLoginUserMutation,
  useSendRestorePassEmailMutation,
  useChangePasswordMutation,
} = authApi;
