import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { Link, useParams } from 'react-router-dom';

// import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { Loader } from '../../components/Loader';
import { API_URL } from '../../constants/api';
import { MODAL_ANCHOR_ID } from '../../constants/base';
import { useLoader } from '../../hooks/useLoader';
import { useGetClientMenuCategoryQuery } from '../../redux/api/menu';
import { useAppSelector } from '../../redux/hooks';

import CategoryCard from './components/Categories/CategoryCard';
// import Slider from './components/Categories/Slider';
import Header from './components/Main/Header';
import { LanguageSelect } from './shared/LanguageSelect';
import Logo from './shared/Logo';
import PermissionPlaceholder from './shared/PermissionPlaceholder';
import Switch from './shared/Switch';
import Touchbar from './shared/Touchbar';

import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import './shared/style.css';

pdfjs.GlobalWorkerOptions.workerSrc =
  'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js';

const Categories = () => {
  const [pagesAmount, setPagesAmount] = useState<number>(1);
  // const [zoomState, setZoomState] = useState({
  //   scale: 1,
  //   positionX: 0,
  //   positionY: 0,
  // });
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { hash } = useParams();
  const { menuLang } = useAppSelector(state => state.user);
  const { isDarkMode } = useAppSelector(state => state.settings);
  const { data, isLoading, isFetching, refetch } = useGetClientMenuCategoryQuery({
    lang: menuLang || language,
    hash: hash as string,
  });

  const isLoader = useLoader(isLoading, isFetching);

  return (
    <main
      className={`h-full relative overflow-x-hidden pb-[21vw] ${
        language !== 'ge' ? 'font-sourceSans' : 'font-helvetica'
      }`}
      id={MODAL_ANCHOR_ID}
      style={{
        backgroundColor: isDarkMode
          ? data?.darkBgColor ?? '#2e2e2e'
          : data?.lightBgColor ?? '#F3F3F3',
      }}
    >
      {isLoader && <Loader />}
      {data?.subscription.permissions.menu || isLoader ? (
        <>
          <div className="px-[15px]">
            <Header className="mb-[12px] px-0">
              <Switch />
              <Link to={`/menu/${hash}`} className="translate-x-[12px]">
                <Logo topColor={data?.uiTopColor} bottomColor={data?.uiBottomColor} />
              </Link>
              {data ? (
                <LanguageSelect
                  items={data.activeLanguages}
                  className="!bg-transparent"
                  refetch={refetch}
                  defaultLanguage={data.defaultLanguage}
                />
              ) : (
                <div className="py-[2.113vw] h-[40px] px-[44px] max-w-[93px] min-w-[93px] w-[93px]"></div>
              )}
            </Header>
            {data?.hasPdfMenu && data?.pdfMenuUrl ? (
              <div
                className="w-full mb-[18px] max-w-[95vw]"
                // style={{
                //   overflowY: 'scroll',
                //   WebkitOverflowScrolling: 'touch',
                // }}
              >
                {/* <TransformWrapper
                  minScale={0.97}
                  maxScale={4}
                  initialScale={0.97}
                  wheel={{ disabled: true }}
                  pinch={{ disabled: false }}
                  doubleClick={{ disabled: true }}
                  panning={{ disabled: false }}
                  onZoom={({ state: { scale, positionX, positionY } }) => {
                    setZoomState({ scale, positionX, positionY });
                  }}
                >
                  <TransformComponent
                    wrapperClass="h-[90vh] overflow-scroll"
                    wrapperStyle={{
                      overflowX: 'hidden', // block X scroll from wrapper
                      overflowY: 'visible', // allow scroll
                    }}
                    contentStyle={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      transform: `translate(${zoomState.positionX}px, 0px) scale(${zoomState.scale})`,
                      transformOrigin: 'top center',
                    }}
                  > */}
                {/* <div style={{ touchAction: 'none' }} className="w-full"> */}
                <Document
                  file={`${API_URL}/menu/pdf/${data.pdfMenuUrl}`}
                  className="overflow-x-auto"
                  onLoadSuccess={({ numPages }) => setPagesAmount(numPages)}
                >
                  {Array.from(new Array(pagesAmount), (_, index) => (
                    <div key={`page_${index + 1}`}>
                      <Page pageNumber={index + 1} width={window.innerWidth} />
                    </div>
                  ))}
                </Document>
                {/* </div> */}
                {/* </TransformComponent>
                </TransformWrapper> */}
              </div>
            ) : (
              <>
                {data?.menuPromoImage && (
                  <div className="w-full h-[35vw] rounded-[16px] overflow-hidden mb-[12px]">
                    <img
                      src={`${API_URL}/image/${data.menuPromoImage}`}
                      className="w-full h-full object-cover"
                    />
                  </div>
                )}
                <h2 className="dark:text-white text-[28px] leading-[32px] font-bold mb-[18px] text-blackBg">
                  {t('foodMenu.client.categoryTitle')}
                </h2>
                {data && (
                  <div className="grid grid-cols-2 gap-[10px] justify-between">
                    {data.menu_categories.map((el, i) => (
                      <CategoryCard key={i} name={el.name} image={el.image} id={el.id} />
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
          <Touchbar
            tipsLink={data?.qrLink}
            topColor={data?.uiTopColor}
            bottomColor={data?.uiBottomColor}
            permissions={data?.subscription.permissions}
          />
        </>
      ) : (
        <PermissionPlaceholder title="foodMenu.client.noPermission" />
      )}
    </main>
  );
};

export default Categories;
