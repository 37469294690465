import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  title: string;
}

const PermissionPlaceholder: FC<IProps> = ({ title }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full flex items-center justify-center">
      <p className="max-w-[90%] text-center text-[24px]">{t(title)}</p>
    </div>
  );
};

export default PermissionPlaceholder;
