import { GET_USER_REVIEWS } from '../../constants/api';

import { IGetReviewParams, IReviewResponse } from './types/reviews';
import baseApi from './base';

const reviewsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getUserReview: builder.query<IReviewResponse, IGetReviewParams>({
      query: params => ({
        url: `${GET_USER_REVIEWS}${params.id}?period=${params.period}&receiver=${params.receiver}`,
      }),
    }),
    updateReviewStatus: builder.mutation<void, number>({
      query: params => ({
        url: `${GET_USER_REVIEWS}${params}`,
        method: 'PUT',
      }),
    }),
  }),
});

export const { useGetUserReviewQuery, useUpdateReviewStatusMutation } = reviewsApi;
