import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useDeleteMenuItemMutation, useUpdateMenuItemMutation } from '../../redux/api/menu';
import { IMenuItem } from '../../redux/api/types/menu';
import { ReactComponent as PlusIcon } from '../../static/plus.svg';
import { Button } from '../../view/Button';
import { MenuLanguageSelect } from '../../view/MenuLanguageSelect';
import Pagination from '../../view/Pagination';
import SearchBar from '../../view/SearchBar';
import { Loader } from '../Loader';
import { Table } from '../MenuTable';

import CategoryBlock from './components/CategoryBlock';
import MenuItemForm from './components/MenuItemForm';
import { useMenuCategoryBody } from './hooks/useMenuCategoryBody';

const MenuCategoryBody = () => {
  const {
    data,
    isLoader,
    refetch,
    open,
    isOpen,
    close,
    items,
    handleSearch,
    searchValue,
    setItems,
    page,
    setPage,
    menuLang,
  } = useMenuCategoryBody();

  const [currentData, setCurrentData] = useState<IMenuItem>();
  const [update] = useUpdateMenuItemMutation();
  const [deleteItem] = useDeleteMenuItemMutation();
  const { categoryId } = useParams();
  const { t } = useTranslation();

  const editHandler = (id: number) => () => {
    setCurrentData(items.find(el => el.id === id));
    open();
  };
  const deleteHandler = (id: number) => () => {
    deleteItem(id).then(refetch);
  };

  const onPageChange = (nextPage: number) => {
    if (data) {
      setItems(data.data.items.slice((nextPage - 1) * 8, nextPage * 8));
    }
  };

  const handleClose = () => {
    close();
    setCurrentData(undefined);
  };

  const visibilityHandler = (id: number) => () => {
    const item = items.find(el => el.id === id);
    const formData = new FormData();
    formData.append('visible', String(!item?.visible));
    formData.append('id', String(item?.id));
    formData.append('lang', menuLang);
    if (categoryId) {
      formData.append('menuCategoryId', categoryId);
    }
    update(formData).then(refetch);
  };

  return (
    <div className="bg-white px-[2.604vw] pt-[2.083vw] pb-[1.203vw] xs:px-[5.333vw] md:px-[3.255vw] relative min-h-[86vh]">
      {isLoader && <Loader />}
      {data && (
        <>
          <CategoryBlock image={data.data.image} name={data.data.name} />
          <div className="flex justify-between items-center pb-4 xs:flex-col">
            <Button ghost={true} onClick={open} className="xs:w-full xs:order-2">
              <PlusIcon />
              <p>{t('foodMenu.createDish')}</p>
            </Button>
            <MenuLanguageSelect items={data.data.languages} refetch={refetch} />
          </div>
        </>
      )}
      {isOpen && (
        <MenuItemForm
          refetch={refetch}
          onClose={handleClose}
          currentData={currentData}
          categories={data?.categories}
          maxOrder={data?.data.maxOrder}
        />
      )}
      <div
        className={`p-[1.042vw] shadow-org rounded-[0.625vw] h-[44.479vw] flex flex-col gap-[1.042vw]
           md:p-[2.604vw] md:rounded-[1.563vw] md:h-[113.542vw] md:gap-[2.083vw]
           xs:p-[5.333vw] xs:rounded-[3.2vw] xs:h-[165.333vw] xs:gap-[3.2vw] md:overflow-hidden`}
      >
        <p className="text-mainBlack text-[1.25vw] leading-[1.771vw] md:text-[2.604vw] md:leading-[3.906vw] xs:text-[4.533vw] xs:leading-[6.4vw]">
          {t('foodMenu.table.itemsTitle')}
        </p>
        <SearchBar searchValue={searchValue} handleSearch={handleSearch} />
        {data && !!items.length ? (
          <>
            <Table
              data={items}
              maxOrder={data.data.maxOrder}
              refetch={refetch}
              updateFn={update}
              acceptHandler={editHandler}
              deleteHandler={deleteHandler}
              visibilityHandler={visibilityHandler}
            />
            <Pagination
              page={page}
              setPage={nextPage => setPage(nextPage)}
              pages={data.data.items.length}
              onPageChange={onPageChange}
            />
          </>
        ) : (
          <p
            className={`text-[1.25vw] leading-[1.771vw] text-lowGray mt-[2.083vw] text-center
            md:mt-[5.208vw] xs:mt-[10.667vw] md:text-[2.083vw] md:leading-[3.125vw] xs:text-[3.733vw] xs:leading-[5.333vw]`}
          >
            {t('organizations.noData')}
          </p>
        )}
      </div>
    </div>
  );
};

export default MenuCategoryBody;
