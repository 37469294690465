import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { API_URL } from '../../../constants/api';
import { ROUTES } from '../../../constants/routes';
import useMediaQuery from '../../../pages/Landing/components/share/useQuery';
import { useAppSelector } from '../../../redux/hooks';
import { ReactComponent as EditIcon } from '../../../static/edit.svg';
import { ReactComponent as TrashIcon } from '../../../static/trash.svg';
import { IMenuTableData, OrderUpdateFn } from '../../../types/base';
import { Button } from '../../../view/Button';
import OrderSelect from '../../../view/OrderSelect';
import Switch from '../../../view/Switch';

interface IProps {
  item: IMenuTableData;
  deleteHandler: (id: number) => () => void;
  acceptHandler: (id: number) => () => void;
  visibilityHandler: (id: number) => () => void;
  maxOrder: number;
  refetch: () => void;
  updateFn: OrderUpdateFn;
  link?: boolean;
}

export const TableRow: FC<IProps> = ({
  item,
  maxOrder,
  refetch,
  updateFn,
  acceptHandler,
  deleteHandler,
  visibilityHandler,
  link,
}) => {
  const { createdAt, name, order, id } = item;
  const { id: userId } = useAppSelector(state => state.user);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleNavigate = () => {
    navigate(`${ROUTES.DASHBOARD}/${userId}${ROUTES.MENU}/${id}`);
  };

  return (
    <div className="md:w-max md:overflow-visible md:pr-5">
      <div
        className={`flex items-center justify-between text-[1.042vw]
        leading-[1.563vw] text-mainBlack bg-white
        border-b-[1px] border-midGray py-[0.521vw] md:py-[1.302vw] xs:px-0
        xs:text-[3.733vw] xs:leading-[5.333vw] md:text-[2.604vw] md:leading-[3.906vw]
        md:gap-[6.51vw] md:w-min
      `}
      >
        <p className="w-[10.719vw] md:w-[21.901vw] xs:w-[30.133vw] text-ellipsis overflow-hidden whitespace-nowrap text-[20px] leading-[30px] xs:text-[14px] xs:leading-[20px]">
          {new Date(createdAt).toLocaleDateString('ru-RU', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })}
        </p>
        {link ? (
          <a
            className="w-[10.875vw] md:w-[21.901vw] xs:w-[30.133vw] text-ellipsis overflow-hidden underline cursor-pointer text-blue-600 flex gap-[14px] items-center"
            onClick={handleNavigate}
          >
            <img
              src={item.image ? `${API_URL}/image/${item.image}` : '/images/upload.png'}
              className="w-[1.875vw] h-[1.25vw] rounded-[4px] md:w-[4.688vw] md:h-[3.125vw]"
            />
            <p className="w-[90%] text-ellipsis overflow-hidden whitespace-nowrap">{name}</p>
          </a>
        ) : (
          <p className="w-[10.875vw] md:w-[21.901vw] xs:w-[30.133vw] text-ellipsis overflow-hidden flex gap-[14px] items-center">
            <img
              src={item.image ? `${API_URL}/image/${item.image}` : '/images/upload.png'}
              className="w-[1.875vw] h-[1.25vw] rounded-[4px] md:w-[4.688vw] md:h-[3.125vw]"
            />
            <p className="w-[90%] text-ellipsis overflow-hidden whitespace-nowrap">{name}</p>
          </p>
        )}
        <div className="w-[10.417vw] md:w-[21.901vw] xs:w-[35.133vw] flex">
          <OrderSelect
            items={new Array(maxOrder).fill(0).map((_, i) => i + 1)}
            refetch={refetch}
            currentOrder={order}
            id={id}
            updateFn={updateFn}
          />
        </div>
        <div className="w-[10.417vw] md:w-[21.901vw] xs:w-[25.133vw] flex justify-center">
          <Switch isChecked={item.visible} onChange={visibilityHandler(item.id)} />
        </div>
        <div className="w-[10.417vw] md:w-[21.901vw] xs:w-[25.133vw]">
          <Button
            onClick={acceptHandler(item.id)}
            ghost={true}
            className="flex gap-[10px] text-textGray w-full xs:w-[15.133vw] xs:ml-auto"
            ghostStyles="py-0 px-0"
          >
            {!isMobile && <p>{t('changeBtn')}</p>}
            <EditIcon className="[&>path]:fill-textGray" />
          </Button>
        </div>
        <div className="w-[10.417vw] md:w-[21.901vw] xs:w-[25.133vw]">
          <Button
            onClick={deleteHandler(item.id)}
            ghost={true}
            className="flex gap-[10px] text-mainRed w-full xs:w-[15.133vw] xs:ml-auto"
            ghostStyles="py-0 px-0 border-mainRed"
          >
            {!isMobile && <p>{t('deleteBtn')}</p>}
            <TrashIcon className="[&>path]:fill-mainRed" />
          </Button>
        </div>
      </div>
    </div>
  );
};
