import { ReactComponent as Line } from '../../../../../static/flight-line.svg';

const ImagesBG = ({ topColor, bottomColor }: { topColor?: string; bottomColor?: string }) => {
  return (
    <div className="w-full">
      <svg
        width="291"
        height="281"
        viewBox="0 0 291 281"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute -right-[26.4vw] -top-[6.667vw]"
      >
        <path
          d="M242.907 159.164C242.108 162.338 239.732 164.877 236.619 165.885L209.53 174.652C206.314 175.693 202.786 174.946 200.268 172.691L179.64 154.221C177.006 151.864 175.9 148.239 176.767 144.812L183.644 117.653C184.446 114.486 186.819 111.953 189.928 110.947L217.838 101.914C221.073 100.867 224.62 101.628 227.14 103.91L247.073 121.957C249.675 124.313 250.766 127.912 249.91 131.316L242.907 159.164Z"
          stroke="url(#paint0_linear_54_6806)"
          strokeOpacity="0.8"
        />
        <path
          d="M191.658 215.392C190.86 218.566 188.484 221.106 185.37 222.113L158.282 230.88C155.065 231.921 151.538 231.175 149.019 228.919L128.391 210.45C125.758 208.092 124.652 204.467 125.519 201.04L132.395 173.882C133.197 170.714 135.571 168.181 138.679 167.175L166.59 158.142C169.824 157.095 173.371 157.856 175.891 160.138L195.824 178.185C198.427 180.541 199.517 184.14 198.661 187.545L191.658 215.392Z"
          stroke="url(#paint1_linear_54_6806)"
          strokeOpacity="0.8"
        />
        <path
          d="M167.905 142C167.107 145.174 164.731 147.714 161.617 148.722L134.529 157.489C131.312 158.53 127.785 157.783 125.266 155.528L104.638 137.058C102.005 134.7 100.899 131.075 101.766 127.649L108.642 100.49C109.444 97.3223 111.818 94.7892 114.927 93.7831L142.837 84.75C146.071 83.7033 149.618 84.4645 152.138 86.7461L172.072 104.794C174.674 107.15 175.765 110.749 174.908 114.153L167.905 142Z"
          stroke="url(#paint2_linear_54_6806)"
          strokeOpacity="0.8"
        />
        <path
          d="M95.2229 127.479C94.4247 130.653 92.0489 133.192 88.935 134.2L61.8464 142.967C58.63 144.008 55.1027 143.261 52.5841 141.006L31.9561 122.536C29.3228 120.178 28.2162 116.554 29.0838 113.127L35.9599 85.9682C36.7619 82.8007 39.1354 80.2676 42.2441 79.2615L70.1546 70.2284C73.3889 69.1816 76.936 69.9429 79.4561 72.2245L99.3891 90.272C101.991 92.628 103.082 96.227 102.226 99.6313L95.2229 127.479Z"
          stroke="url(#paint3_linear_54_6806)"
          strokeOpacity="0.8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.617 107.591L110.346 124.46C109.433 128.066 110.598 131.882 113.37 134.364L125.647 145.357L125.116 143.539L114.037 133.619C111.542 131.385 110.494 127.951 111.316 124.705L115.17 109.483L114.617 107.591Z"
          fill="url(#paint4_linear_54_6806)"
          fillOpacity="0.8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M162.197 132.752L166.122 117.143C167.023 113.56 165.875 109.771 163.136 107.291L152.606 97.7579L153.098 99.5521L162.465 108.033C164.93 110.265 165.963 113.674 165.152 116.899L161.659 130.79L162.197 132.752Z"
          fill="url(#paint5_linear_54_6806)"
          fillOpacity="0.8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M139.586 209.517L150.998 219.734C153.649 222.108 157.362 222.894 160.747 221.798L175.39 217.059L173.423 216.645L160.439 220.847C157.392 221.833 154.051 221.126 151.665 218.989L141.547 209.93L139.586 209.517Z"
          fill="url(#paint6_linear_54_6806)"
          fillOpacity="0.8"
        />
        <defs>
          <linearGradient
            id="paint0_linear_54_6806"
            x1="245.343"
            y1="160.3"
            x2="223.366"
            y2="174.608"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={topColor ?? '#FFB800'} />
            <stop offset="1" stopColor={bottomColor ?? '#FFA825'} />
          </linearGradient>
          <linearGradient
            id="paint1_linear_54_6806"
            x1="194.095"
            y1="216.528"
            x2="172.118"
            y2="230.836"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={topColor ?? '#FFB800'} />
            <stop offset="1" stopColor={bottomColor ?? '#FFA825'} />
          </linearGradient>
          <linearGradient
            id="paint2_linear_54_6806"
            x1="170.342"
            y1="143.137"
            x2="148.365"
            y2="157.445"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={topColor ?? '#FFB800'} />
            <stop offset="1" stopColor={bottomColor ?? '#FFA825'} />
          </linearGradient>
          <linearGradient
            id="paint3_linear_54_6806"
            x1="97.6598"
            y1="128.615"
            x2="75.6826"
            y2="142.923"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={topColor ?? '#FFB800'} />
            <stop offset="1" stopColor={bottomColor ?? '#FFA825'} />
          </linearGradient>
          <linearGradient
            id="paint4_linear_54_6806"
            x1="117.508"
            y1="107.591"
            x2="123.32"
            y2="108.507"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={topColor ?? '#FFB800'} />
            <stop offset="1" stopColor={bottomColor ?? '#FFA825'} />
          </linearGradient>
          <linearGradient
            id="paint5_linear_54_6806"
            x1="160.085"
            y1="99.2629"
            x2="164.65"
            y2="99.8465"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={topColor ?? '#FFB800'} />
            <stop offset="1" stopColor={bottomColor ?? '#FFA825'} />
          </linearGradient>
          <linearGradient
            id="paint6_linear_54_6806"
            x1="156.72"
            y1="209.517"
            x2="163.089"
            y2="216.334"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={topColor ?? '#FFB800'} />
            <stop offset="1" stopColor={bottomColor ?? '#FFA825'} />
          </linearGradient>
        </defs>
      </svg>
      <Line className="absolute left-[7.2vw] top-[13.6vw]" />
      <svg
        width="142"
        height="242"
        viewBox="0 0 142 242"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute top-[91.2vw]"
      >
        <path
          d="M94.6206 177.663C92.6064 180.243 89.4061 181.604 86.1508 181.265L57.8312 178.322C54.4687 177.973 51.5452 175.863 50.1544 172.781L38.7635 147.545C37.3094 144.323 37.7642 140.56 39.9439 137.778L57.2209 115.724C59.2359 113.151 62.4314 111.795 65.6814 112.133L94.8601 115.165C98.2413 115.517 101.177 117.648 102.558 120.754L113.485 145.324C114.911 148.531 114.453 152.264 112.292 155.03L94.6206 177.663Z"
          stroke="url(#paint0_linear_54_6822)"
          strokeOpacity="0.8"
        />
        <path
          d="M25.004 208.347C22.9898 210.927 19.7895 212.288 16.5342 211.95L-11.7853 209.007C-15.1479 208.657 -18.0714 206.547 -19.4622 203.466L-30.853 178.229C-32.3072 175.007 -31.8524 171.245 -29.6727 168.462L-12.3957 146.408C-10.3807 143.836 -7.18519 142.48 -3.93522 142.817L25.2435 145.85C28.6248 146.201 31.5605 148.332 32.9419 151.439L43.8682 176.008C45.2945 179.215 44.836 182.948 42.6756 185.715L25.004 208.347Z"
          stroke="url(#paint1_linear_54_6822)"
          strokeOpacity="0.8"
        />
        <path
          d="M32.9776 131.621C30.9634 134.2 27.7632 135.561 24.5078 135.223L-3.8117 132.28C-7.17426 131.931 -10.0978 129.82 -11.4886 126.739L-22.8794 101.502C-24.3335 98.2806 -23.8788 94.518 -21.699 91.7355L-4.42203 69.6813C-2.40702 67.1091 0.788444 65.753 4.03841 66.0907L33.2171 69.123C36.5984 69.4744 39.5342 71.6057 40.9155 74.7119L51.8418 99.2813C53.2682 102.489 52.8096 106.221 50.6493 108.988L32.9776 131.621Z"
          stroke="url(#paint2_linear_54_6822)"
          strokeOpacity="0.8"
        />
        <path
          d="M-27.6139 88.9325C-29.6281 91.5122 -32.8284 92.8734 -36.0837 92.5351L-64.4032 89.592C-67.7658 89.2426 -70.6893 87.1325 -72.0801 84.0511L-83.471 58.8143C-84.9251 55.5926 -84.4703 51.83 -82.2906 49.0475L-65.0136 26.9933C-62.9986 24.4211 -59.8031 23.065 -56.5531 23.4027L-27.3744 26.435C-23.9932 26.7864 -21.0574 28.9178 -19.676 32.0239L-8.74976 56.5933C-7.32338 59.8007 -7.78195 63.5333 -9.94229 66.3001L-27.6139 88.9325Z"
          stroke="url(#paint3_linear_54_6822)"
          strokeOpacity="0.8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.76106 79.0055L-12.5029 92.6954C-14.7997 95.6225 -15.2814 99.5828 -13.7535 102.975L-6.98584 118.001L-6.73438 116.124L-12.8417 102.565C-14.2169 99.5113 -13.7833 95.9471 -11.7162 93.3127L-2.02281 80.959L-1.76106 79.0055Z"
          fill="url(#paint4_linear_54_6822)"
          fillOpacity="0.8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.5358 121.293L41.4513 108.615C43.7276 105.704 44.2134 101.775 42.7146 98.398L36.9531 85.4149L36.6754 87.2544L41.8006 98.8036C43.1495 101.843 42.7123 105.379 40.6636 107.999L31.8396 119.281L31.5358 121.293Z"
          fill="url(#paint5_linear_54_6822)"
          fillOpacity="0.8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.6968 171.843L81.9428 173.317C85.4849 173.66 88.9421 172.094 91.0211 169.206L100.012 156.714L98.182 157.546L90.2095 168.621C88.3384 171.221 85.227 172.63 82.0391 172.322L68.5209 171.015L66.6968 171.843Z"
          fill="url(#paint6_linear_54_6822)"
          fillOpacity="0.8"
        />
        <defs>
          <linearGradient
            id="paint0_linear_54_6822"
            x1="94.925"
            y1="180.499"
            x2="51.5273"
            y2="117.263"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={topColor ?? '#FFC121'} />
            <stop offset="0.640625" stopColor={bottomColor ?? '#FFA400'} />
            <stop offset="1" stopColor={bottomColor ?? '#FF9900'} />
          </linearGradient>
          <linearGradient
            id="paint1_linear_54_6822"
            x1="25.3085"
            y1="211.183"
            x2="-18.0893"
            y2="147.947"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={topColor ?? '#FFC121'} />
            <stop offset="0.640625" stopColor={bottomColor ?? '#FFA400'} />
            <stop offset="1" stopColor={bottomColor ?? '#FF9900'} />
          </linearGradient>
          <linearGradient
            id="paint2_linear_54_6822"
            x1="33.2821"
            y1="134.456"
            x2="-10.1156"
            y2="71.2205"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={topColor ?? '#FFC121'} />
            <stop offset="0.640625" stopColor={bottomColor ?? '#FFA400'} />
            <stop offset="1" stopColor={bottomColor ?? '#FF9900'} />
          </linearGradient>
          <linearGradient
            id="paint3_linear_54_6822"
            x1="-27.3094"
            y1="91.7682"
            x2="-70.7072"
            y2="28.5326"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={topColor ?? '#FFC121'} />
            <stop offset="0.640625" stopColor={bottomColor ?? '#FFA400'} />
            <stop offset="1" stopColor={bottomColor ?? '#FF9900'} />
          </linearGradient>
          <linearGradient
            id="paint4_linear_54_6822"
            x1="0.88192"
            y1="80.1774"
            x2="5.82347"
            y2="83.3711"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={topColor ?? '#FFB800'} />
            <stop offset="1" stopColor={bottomColor ?? '#FFA825'} />
          </linearGradient>
          <linearGradient
            id="paint5_linear_54_6822"
            x1="43.1803"
            y1="89.8223"
            x2="47.1167"
            y2="92.206"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={topColor ?? '#FFB800'} />
            <stop offset="1" stopColor={bottomColor ?? '#FFA825'} />
          </linearGradient>
          <linearGradient
            id="paint6_linear_54_6822"
            x1="80.5015"
            y1="161.696"
            x2="89.6708"
            y2="163.416"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={topColor ?? '#FFB800'} />
            <stop offset="1" stopColor={bottomColor ?? '#FFA825'} />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default ImagesBG;
