import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IMenuTableData, OrderUpdateFn } from '../../../types/base';

import { TableRow } from './TableRow';

interface IProps {
  data: IMenuTableData[];
  deleteHandler: (id: number) => () => void;
  acceptHandler: (id: number) => () => void;
  visibilityHandler: (id: number) => () => void;
  maxOrder: number;
  refetch: () => void;
  updateFn: OrderUpdateFn;
  link?: boolean;
}

export const Table: FC<IProps> = ({
  data,
  acceptHandler,
  deleteHandler,
  maxOrder,
  refetch,
  updateFn,
  visibilityHandler,
  link,
}) => {
  const { t } = useTranslation();

  return (
    <div className="overflow-auto h-full md:w-full md:overflow-scroll">
      <div
        className={`flex items-center justify-between text-[1.042vw]
          leading-[1.563vw] text-textGray bg-white
          border-b-[1px] border-midGray py-[0.521vw] md:py-[1.302vw] xs:pt-[2.667vw] xs:pb-[3.733vw]
          md:text-[2.083vw] md:leading-[3.125vw] xs:text-[3.733vw] xs:leading-[5.333vw]
          md:w-min md:gap-[6.51vw]
        `}
      >
        <p className="w-[10.719vw] md:w-[21.901vw] xs:w-[35.133vw]">{t('foodMenu.table.date')}</p>
        <p className="w-[10.875vw] md:w-[21.901vw] xs:w-[30.133vw]">{t('foodMenu.table.name')}</p>
        <p className="w-[10.417vw] md:w-[21.901vw] xs:w-[30.133vw] whitespace-nowrap">
          {t('foodMenu.table.order')}
        </p>
        <p className="w-[10.031vw] text-right md:w-[21.901vw]  xs:w-[25.133vw] flex justify-center">
          {t('foodMenu.table.visibility')}
        </p>
        <p className="w-[10.031vw] text-right md:w-[21.901vw]  xs:w-[25.133vw]">
          {t('foodMenu.table.edit')}
        </p>
        <p className="w-[10.573vw] text-right md:w-[21.901vw]  xs:w-[25.133vw]">
          {t('foodMenu.table.delete')}
        </p>
      </div>
      {data.map(el => (
        <TableRow
          item={el}
          key={el.id}
          acceptHandler={acceptHandler}
          deleteHandler={deleteHandler}
          visibilityHandler={visibilityHandler}
          maxOrder={maxOrder}
          refetch={refetch}
          updateFn={updateFn}
          link={link}
        />
      ))}
    </div>
  );
};
