import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Loader } from '../../components/Loader';
import { MODAL_ANCHOR_ID } from '../../constants/base';
import { useDisclosure } from '../../hooks/useDisclosure';
import { useLoader } from '../../hooks/useLoader';
import { useGetClientMenuItemsQuery } from '../../redux/api/menu';
import { IMenuItem } from '../../redux/api/types/menu';
import { useAppSelector } from '../../redux/hooks';
import { ReactComponent as Arrow } from '../../static/arrow_down.svg';
import { ReactComponent as PlateIcon } from '../../static/plate_view.svg';
import { ReactComponent as RowIcon } from '../../static/row_view.svg';

import MenuHeader from './components/Main/Header';
import ItemsBlock from './components/MenuItems/ItemsBlock';
import { Modal } from './components/MenuItems/Modal';
import SwiperBlock from './components/MenuItems/Swiper';
import { useDarkTheme } from './shared/hooks/useDarkTheme';
import { LanguageSelect } from './shared/LanguageSelect';
import Logo from './shared/Logo';
import PermissionPlaceholder from './shared/PermissionPlaceholder';
import Touchbar from './shared/Touchbar';

import './shared/style.css';

const MenuItems = () => {
  const { hash } = useParams();
  const { hash: categoryId } = useLocation();
  const {
    i18n: { language },
  } = useTranslation();
  const { menuLang } = useAppSelector(state => state.user);
  const { isDarkMode } = useAppSelector(state => state.settings);
  const [currentCategory, setCurrentCategory] = useState(+(categoryId as string).slice(1));
  const { isOpen, open, close } = useDisclosure();
  const [lastY, setLastY] = useState(0);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [afterClick, setAfterClick] = useState(false);
  const navigate = useNavigate();
  const { data, isLoading, isFetching } = useGetClientMenuItemsQuery({
    lang: menuLang ?? language,
    hash: hash as string,
  });
  const isLoader = useLoader(isLoading, isFetching);
  const [plateView, setPlateView] = useState(true);
  const [currentDish, setCurrentDish] = useState<IMenuItem>();
  const [mounted, setMounted] = useState(false);
  useDarkTheme();

  useEffect(() => {
    setCurrentCategory(+(categoryId as string).slice(1));
  }, [categoryId]);

  useEffect(() => {
    if (afterClick) {
      setTimeout(() => {
        setAfterClick(false);
      }, 1500);
    }
  }, [afterClick]);

  useEffect(() => {
    setAfterClick(true);
    setMounted(true);
  }, []);

  const scrollHandler = () => {
    setLastY(prev => {
      if (Math.abs((scrollRef.current?.scrollTop as number) - prev) > 50) {
        return scrollRef.current?.scrollTop ?? 0;
      }
      return prev;
    });
  };

  return (
    <main
      className={`dark:bg-none dark:bg-darkBg bg-categoryBg h-full relative overflow-x-hidden pb-[15px] min-h-[110vw] scroll ${
        isOpen && 'overflow-hidden'
      } ${language !== 'ge' ? 'font-sourceSans' : 'font-helvetica'}`}
      id={MODAL_ANCHOR_ID}
      onScroll={scrollHandler}
      ref={scrollRef}
      style={{
        backgroundColor: isDarkMode
          ? data?.darkBgColor ?? '#2e2e2e'
          : data?.lightBgColor ?? '#F3F3F3',
      }}
    >
      {isLoader && <Loader />}
      {data?.subscription.permissions.menu ? (
        <>
          <div
            className={`flex-col sticky z-10 mb-0 pb-[5px] px-[15px] ${
              !isOpen && 'sticky top-0 dark:bg-darkBg bg-white'
            }`}
          >
            <MenuHeader className="px-0 z-10">
              <Link
                to={'..'}
                onClick={e => {
                  e.preventDefault();
                  if (isOpen) close?.();
                  else navigate(`/menu/${hash}/categories`);
                }}
                className="w-[68px] h-[42px] dark:bg-none dark:bg-darkBg bg-white rounded-full"
              >
                <Arrow className="rotate-90 [&>path]:dark:fill-white [&>path]:fill-black w-[40px] h-[40px]" />
              </Link>
              {!isOpen && (
                <Link to={`/menu/${hash}`} className="translate-x-[12px]">
                  <Logo topColor={data?.uiTopColor} bottomColor={data?.uiBottomColor} />
                </Link>
              )}
              {data ? (
                <LanguageSelect
                  items={data.activeLanguages}
                  className="!bg-transparent"
                  defaultLanguage={data.defaultLanguage}
                />
              ) : (
                <div className="py-[2.113vw] h-[40px] px-[44px] max-w-[93px] min-w-[93px] w-[93px]"></div>
              )}
            </MenuHeader>
            {data && (
              <SwiperBlock
                setAfterClick={setAfterClick}
                activeIndex={currentCategory}
                items={data.categories}
              />
            )}
          </div>
          <div className="px-[15px] h-full flex flex-col">
            <div className="flex items-center gap-[8px] ml-auto translate-y-4">
              <button
                className={`p-[8px] dark:bg-[#4D4D4D] bg-white rounded-[8px] ${
                  plateView && 'border-[1px]'
                }`}
                style={{
                  borderColor: data?.uiTopColor ?? '#efb916',
                }}
                onClick={() => setPlateView(true)}
              >
                <PlateIcon className="[&>path]:fill-black [&>path]:dark:fill-white" />
              </button>
              <button
                className={`p-[8px] dark:bg-[#4D4D4D] bg-white rounded-[8px] ${
                  !plateView && 'border-[1px] border-mainOrange'
                }`}
                style={{
                  borderColor: data?.uiBottomColor ?? '#efb916',
                }}
                onClick={() => setPlateView(false)}
              >
                <RowIcon className="[&>path]:fill-black [&>path]:dark:fill-white" />
              </button>
            </div>
            {data && mounted && (
              <>
                {data.items.map((el, i) => (
                  <div
                    key={el[0].id}
                    className={
                      i === data.items.length - 1
                        ? 'pb-[21vw] -mt-[120px] pt-[150px]'
                        : '-mt-[120px] pt-[150px]'
                    }
                  >
                    <p className="dark:text-white text-black text-[24px] font-bold mb-2">
                      {i !== 0 && el[0].categoryName}
                    </p>
                    <ItemsBlock
                      open={open}
                      items={el}
                      categories={data.categories}
                      currentCategory={currentCategory}
                      selfCategory={data.categories[i].id}
                      setCurrentCategory={setCurrentCategory}
                      plateView={plateView}
                      afterClick={afterClick}
                      setCurrentDish={setCurrentDish}
                      isDownScroll={(scrollRef.current?.scrollTop as number) >= lastY}
                      scrollBlock={scrollRef.current}
                      color={data?.uiTopColor}
                    />
                  </div>
                ))}
                {currentDish && (
                  <Modal
                    topColor={data?.uiTopColor}
                    bottomColor={data?.uiBottomColor}
                    isOpen={isOpen}
                    item={currentDish}
                    close={close}
                    bgColor={{
                      dark: data?.darkBgColor,
                      light: data?.lightBgColor,
                    }}
                  />
                )}
              </>
            )}
          </div>
          <Touchbar
            isOpen={isOpen}
            tipsLink={data?.qrLink}
            topColor={data?.uiTopColor}
            bottomColor={data?.uiBottomColor}
            permissions={data?.subscription.permissions}
          />
        </>
      ) : (
        <PermissionPlaceholder title="foodMenu.client.noPermission" />
      )}
    </main>
  );
};

export default MenuItems;
