import { fetchUrl } from '../config';

export const API_URL = fetchUrl;
export const CONFIRM_REGISTRATION = `${API_URL}/user/email/`;
export const RESET_PASSWORD = `${API_URL}/user/password/restore/`;
export const REGISTER = `${API_URL}/user/register`;
export const LOGIN = `${API_URL}/user/login`;
export const RESTORE_PASS_EMAIL = `${API_URL}/user/password/restore`;
export const CHANGE_PASSWORD = `${API_URL}/user/password/change`;
export const GET_USER_REVIEWS = `${API_URL}/review/`;
export const UPDATE_REVIEW = `${API_URL}/review/`;
export const CREATE_REVIEW = `${API_URL}/review/`;
export const UPDATE_USER = `${API_URL}/user`;
export const GET_USER = `${API_URL}/user`;
export const GET_EMPLOYYES_CARDS = `${API_URL}/user/employees`;
export const DELETE_USER = `${API_URL}/user`;
export const CREATE_TG_CODE = `${API_URL}/user/tg`;
export const ADMIN_DATA = `${API_URL}/user/admin`;
export const USER_QR = `${API_URL}/qr`;
export const USER_QR_UPDATE = `${API_URL}/qr/update`;
export const PAYMENT_DATA = `${API_URL}/user/payment`;
export const GET_EMPLOYEE_DATA = `${API_URL}/user/employees/user`;
export const PDF_DOWNLOAD = `${API_URL}/pdf`;
export const PAYMENT_CREATE = `${API_URL}/payment/create`;
export const PAYMENT_CREATE_TBC = `${API_URL}/payment/create-tbc`;
export const CREATE_RECURRING = `${API_URL}/payment/recurring/create`;
export const DELETE_RECURRING = `${API_URL}/payment/recurring/delete`;
export const DELETE_SUBSCRIPTION = `${API_URL}/payment/recurring/delete`;
export const DOCS_TERMS = `${API_URL}/docs/terms`;
export const DOCS_POLICY = `${API_URL}/docs/policy`;
export const AFTER_PAY_DATA = `${API_URL}/redirect`;
export const SEND_AFTER_PAY_DATA = `${API_URL}/redirect/update`;
export const GET_CURRENT_MENU = `${API_URL}/menu`;
export const CREATE_EMPTY_MENU = `${API_URL}/menu`;
export const CREATE_MENU_CATEGORY = `${API_URL}/menu/category`;
export const UPDATE_MENU_CATEGORY = `${API_URL}/menu/category`;
export const GET_MENU_CATEGORY_ITEMS = `${API_URL}/menu/category/item`;
export const CREATE_MENU_ITEM = `${API_URL}/menu/category/item`;
export const UPDATE_MENU_ITEM = `${API_URL}/menu/category/item/image`;
export const UPDATE_MENU_LANGUAGE = `${API_URL}/menu/language`;
export const DELETE_MENU_CATEGORY = `${API_URL}/menu/category`;
export const DELETE_MENU_ITEM = `${API_URL}/menu/category/item`;
export const GET_ADMIN_TRANSACTIONS = `${ADMIN_DATA}/transactions`;
export const UPDATE_MENU_ACTIVE_LANGUAGE = `${API_URL}/menu/active-language`;
export const GET_USER_MENU_MAIN = `${API_URL}/menu/client`;
export const GET_CLIENT_MENU_CATEGORY = `${API_URL}/menu/client/category`;
export const GET_CLIENT_MENU_ITEMS = `${API_URL}/menu/client`;
export const UPDATE_MENU_IMAGE = `${API_URL}/menu`;
export const UPDATE_MENU_BG_IMAGE = `${API_URL}/menu/bg`;
export const UPDATE_MENU_DEFAULT_LANGUAGE = `${API_URL}/menu/default-language`;
