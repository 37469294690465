import { FC, MouseEvent, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { MODAL_ANCHOR_ID } from '../../../constants/base';
import useMediaQuery from '../../../pages/Landing/components/share/useQuery';
import { useUpdateReviewStatusMutation } from '../../../redux/api/reviews';
import { ReactComponent as CloseIcon } from '../../../static/close.svg';
import { Button } from '../../../view/Button';
import ClickAwayListener from '../../../view/ClickAwayListener';
import { Star } from '../../../view/Stars';
import { getTime } from '../helpers';

interface IProps {
  date: Date;
  amount: number;
  receiver: string;
  rating: number;
  type?: string;
  admin?: boolean;
  id: number;
  refetch?: () => void;
}

export const RowItem: FC<IProps> = ({
  date,
  amount,
  receiver,
  rating,
  type,
  admin,
  id,
  refetch,
}) => {
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [updateReview] = useUpdateReviewStatusMutation();

  const confirmHandler = () => {
    updateReview(id)
      .unwrap()
      .then(() => {
        refetch?.();
      });
    setIsModalOpen(false);
  };
  const declineHandler = (e: MouseEvent<HTMLElement>) => {
    if ((e.target as HTMLElement).classList.contains('away')) return;
    setIsModalOpen(false);
  };

  return (
    <div className="w-full flex items-center gap-[2.604vw]  md:gap-[1.204vw] xs:gap-[2.467vw] bg-white">
      <p
        className={`text-mainBlack text-[0.938vw] leading-[1.458vw] py-[0.521vw] w-[4.635vw] md:text-[2.083vw] md:leading-[3.125vw] md:py-[0.391vw] md:w-[9.115vw]
        xs:text-[3.733vw] xs:leading-[5.333vw] xs:py-0 xs:w-[18.667vw] md:shrink-0`}
      >
        {date.toLocaleDateString('ru-RU', {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
        })}
      </p>
      <p
        className={`text-mainBlack text-[1.042vw] leading-[1.563vw] py-[0.521vw] w-[5.677vw] md:text-[2.083vw] md:leading-[3.125vw] md:py-[0.391vw] md:w-[11.719vw]
        xs:text-[3.733vw] xs:leading-[5.333vw] xs:py-0 xs:w-[24vw] md:shrink-0`}
      >
        {getTime(date)}
      </p>
      <p
        className={`font-bold text-mainBlack text-[1.042vw] leading-[1.563vw] py-[0.521vw] w-[5.677vw] md:text-[2.083vw] md:leading-[3.125vw] md:py-[0.391vw] md:w-[11.849vw]
        xs:text-[3.733vw] xs:leading-[5.333vw] xs:py-0 xs:w-[24.267vw] md:shrink-0`}
      >
        {amount} ₾
      </p>
      <p
        className={`text-mainBlack text-[1.042vw] leading-[1.563vw] py-[0.521vw] w-[14.875vw] md:text-[2.083vw] md:leading-[3.125vw] md:py-[0.391vw] md:w-[28.906vw]
        xs:text-[3.733vw] xs:leading-[5.333vw] xs:py-0 xs:w-[59.2vw] md:shrink-0`}
      >
        {receiver}
      </p>
      <div
        className={`text-mainBlack text-[1.042vw] leading-[1.563vw] py-[0.521vw] w-[11.302vw] md:text-[2.083vw] md:leading-[3.125vw] md:py-[0.391vw] md:w-[9.115vw]
        xs:text-[3.733vw] xs:leading-[5.333vw] xs:py-0 xs:w-[18.667vw] md:shrink-0`}
      >
        <Star
          rating={rating}
          className="gap-[0.417vw]"
          size="w-[1.25vw] h-[1.25vw] md:w-[2.344vw] md:h-[2.344vw] xs:w-[4.8vw] xs:h-[4.8vw]"
          full={!isMobile}
        />
      </div>
      {type && admin && (
        <>
          <p
            className={`text-mainBlack text-[1.042vw] leading-[1.563vw] py-[0.521vw] w-[8.875vw] md:text-[2.083vw] md:leading-[3.125vw] md:py-[0.391vw] md:w-[28.906vw]
        xs:text-[3.733vw] xs:leading-[5.333vw] xs:py-0 xs:w-[18.667vw] md:shrink-0`}
          >
            {+type !== 0 ? (
              <span className="text-green-500">{t('analytic.statusSuccess')}</span>
            ) : (
              <span className="text-red-500">{t('analytic.statusReject')}</span>
            )}
          </p>
          {type === '0' && (
            <Button
              className="md:!h-[4.5vw]"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              To success
            </Button>
          )}
          {isModalOpen &&
            createPortal(
              <div className="absolute top-0 left-0 bottom-0 right-0 bg-backdrop z-20 flex items-center justify-center">
                <ClickAwayListener onClickAway={declineHandler}>
                  <div
                    className={`p-[2.083vw] w-[30.104vw] bg-white relative shadow-pass rounded-[0.625vw] away
                            md:p-[5.208vw] md:w-[75.26vw] md:rounded-[1.563vw] xs:p-[10.667vw] xs:w-[89.6vw] xs:rounded-[3.2vw]`}
                  >
                    <p
                      className={`mb-[1.563vw] font-bold text-[1.25vw] leading-[1.771vw] text-mainBlack text-center away
                              md:mb-[3.906vw] md:text-[3.125vw] md:leading-[4.427vw] xs:mb-[8vw] xs:text-[5.333vw] xs:leading-[8vw]`}
                    >
                      Are you sure u want to change status of the transaction?
                    </p>
                    <div className="w-full flex gap-[1.042vw] xs:flex-col md:gap-[2.604vw] xs:gap-[5.333vw]">
                      <button
                        className={`btn w-[48%] xs:w-full bg-white hover:bg-white border-mainOrange
                                  hover:border-mainOrange border-solid hover:border-solid md:h-[6.25vw] xs:h-[12.8vw]
                                  text-mainBlack normal-case font-bold text-[1.042vw] leading-[1.563vw]
                                  md:text-[2.604vw] md:leading-[3.906vw] xs:text-[5.333vw] xs:leading-[8vw]
                                `}
                        style={{ borderWidth: '1px' }}
                        onClick={declineHandler}
                      >
                        {t('cancelBtn')}
                      </button>
                      <Button
                        className="w-[48%] xs:w-full flex items-center gap-[0.521vw] md:gap-[1.302vw] xs:gap-[2.667vw] away"
                        onClick={confirmHandler}
                      >
                        {t('changeBtn')}
                      </Button>
                    </div>
                    <button
                      onClick={declineHandler}
                      className="absolute right-[1.042vw] top-[1.042vw] md:right-[2.604vw] md:top-[2.604vw] xs:right-[4.267vw] xs:top-[4.267vw]"
                    >
                      <CloseIcon
                        className="w-[1.25vw] h-[1.25vw] md:w-[3.125vw] md:h-[3.125vw] xs:w-[6.4vw] xs:h-[6.4vw]"
                        stroke="black"
                      />
                    </button>
                  </div>
                </ClickAwayListener>
              </div>,
              document.getElementById(MODAL_ANCHOR_ID) as HTMLElement
            )}
        </>
      )}
    </div>
  );
};
