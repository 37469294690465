import { ChangeEvent, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as clipboard from 'clipboard-polyfill';

import { TOAST_OPTIONS } from '../../config';
import {
  useDeleteCategoryMutation,
  useDeleteMenuImageMutation,
  useDeleteMenuPdfMutation,
  useUpdateCategoryMutation,
  useUpdateMenuBgImageMutation,
  useUpdateMenuDefaultLanguageMutation,
  useUpdateMenuImageMutation,
  useUploadMenuPdfMutation,
} from '../../redux/api/menu';
import { SubPeriod } from '../../redux/api/types';
import { useAppSelector } from '../../redux/hooks';
import { ReactComponent as PlusIcon } from '../../static/plus.svg';
import { Button } from '../../view/Button';
import { MenuLanguageSelect } from '../../view/MenuLanguageSelect';
import Pagination from '../../view/Pagination';
import SearchBar from '../../view/SearchBar';
import { Select } from '../../view/Select';
import { Loader } from '../Loader';
import { Table } from '../MenuTable';
import FileBlock from '../QRBody/components/Promo/components/FileBlock';

import BuyMenu from './components/BuyMenu';
import CreateCategoryModal from './components/CreateCategoryModal';
import VisibleLanguageSelect from './components/VisibleLanguageSelect';
import { useMenuBody } from './hooks/useMenuBody';

const MenuBody = () => {
  const {
    data,
    isLoader,
    refetch,
    open,
    isOpen,
    createMenuHandler,
    close,
    t,
    categories,
    searchValue,
    handleSearch,
    setCategories,
    setActiveLanguages,
    activeLanguages,
    adminId,
    id: userId,
    page,
    setPage,
  } = useMenuBody();

  const [update] = useUpdateCategoryMutation();
  const [updateDefaultLanguage] = useUpdateMenuDefaultLanguageMutation();
  const [deleteCategory] = useDeleteCategoryMutation();
  const [updateMenuImage, { isLoading }] = useUpdateMenuImageMutation();
  const [updateMenuBgImage, { isLoading: isBgUpdating }] = useUpdateMenuBgImageMutation();
  const [deleteImage, { isLoading: isDeleteLoading }] = useDeleteMenuImageMutation();
  const [uploadPdf] = useUploadMenuPdfMutation();
  const [deletePdf] = useDeleteMenuPdfMutation();
  const navigate = useNavigate();
  const [, setSearch] = useSearchParams();

  const [file, setFile] = useState<File>();
  const [bgFile, setBgFile] = useState<File>();
  const defaultLanguageOptions = useMemo(() => {
    if (data) {
      return data.languages.map(el => ({ title: el.toUpperCase(), value: el }));
    }
    return [];
  }, [data?.languages]);

  const { menuLang, subscription } = useAppSelector(state => state.user);

  const bgFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBgFile(e.target.files?.[0] as File);
    const formData = new FormData();
    formData.append('file', e.target.files?.[0] as File);
    updateMenuBgImage({ id: userId, body: formData });
  };

  const fileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0] as File);
    const formData = new FormData();
    formData.append('file', e.target.files?.[0] as File);
    updateMenuImage({ id: userId, body: formData });
  };

  const editHandler = (id: number) => () => {
    navigate(`${id}`);
  };

  const deleteHandler = (id: number) => () => {
    deleteCategory(id).then(refetch);
  };

  const onPageChange = (nextPage: number) => {
    if (data) {
      setSearch(new URLSearchParams([['page', nextPage.toString()]]));
      setCategories(data.menu_categories.slice((nextPage - 1) * 8, nextPage * 8));
    }
  };

  const copyHandler = () => {
    clipboard.writeText(data?.link || '').then(() => {
      toast.info('Copied to clipboard', { ...TOAST_OPTIONS, position: 'top-center' });
    });
  };

  const handleDelete = () => {
    deleteImage({ id: userId, field: 'menuPromoImage' }).then(() => setFile(undefined));
  };

  const handleDeleteBg = () => {
    deleteImage({ id: userId, field: 'bgImage' }).then(() => setFile(undefined));
  };

  const handleDefaultLanguageChange = (value: string) => {
    if (data?.languages.includes(value)) {
      updateDefaultLanguage({ menuId: data.id, lang: value })
        .unwrap()
        .then(() => {
          toast.success(t('back.user.update'), TOAST_OPTIONS);
        });
    }
  };

  const visibilityHandler = (id: number) => () => {
    const category = categories.find(el => el.id === id);
    const formData = new FormData();
    formData.append('visible', String(!category?.visible));
    formData.append('id', String(category?.id));
    update(formData).then(refetch);
  };

  const handlePdfUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const pdfFile = e.target.files?.[0];
    if (pdfFile && data?.id && menuLang) {
      const formData = new FormData();
      formData.append('file', pdfFile);

      try {
        await uploadPdf({
          menuId: data.id,
          lang: menuLang,
          file: formData,
        }).unwrap();
        toast.success(t('foodMenu.pdfUploaded'), TOAST_OPTIONS);
        refetch();
      } catch (error) {
        toast.error(t('foodMenu.pdfUploadError'), TOAST_OPTIONS);
      }
    }
  };

  const handlePdfDelete = async () => {
    if (data?.id && menuLang) {
      try {
        await deletePdf({
          menuId: data.id,
          lang: menuLang,
        }).unwrap();
        toast.success(t('foodMenu.pdfDeleted'), TOAST_OPTIONS);
        refetch();
      } catch (error) {
        toast.error(t('foodMenu.pdfDeleteError'), TOAST_OPTIONS);
      }
    }
  };

  if (!adminId && (!subscription || !subscription.permissions.menu)) {
    return <BuyMenu />;
  }

  return (
    <div className="bg-white p-[2.604vw] md:px-[3.255vw] md:py-[5.208vw] xs:py-[8vw] xs:px-[5.333vw]">
      {isLoader || ((isLoading || isDeleteLoading || isBgUpdating) && <Loader />)}
      {subscription?.type === SubPeriod.TRIAL && (
        <div className="rounded-[6px] bg-lightGray border-[1px] border-solid border-textGray p-2 my-2">
          <p>
            {t('foodMenu.trial')}:{' '}
            {new Date(+subscription.startDate + +subscription.period).toLocaleDateString('ru-RU', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </p>
        </div>
      )}
      {data ? (
        <>
          <div className="flex gap-4 w-[40vw] md:w-full xs:w-full mb-[10px] h-max">
            <div className="w-1/2 flex flex-col md:self-stretch min-h-full">
              <FileBlock
                onChange={fileChange}
                file={file}
                imageFromDB={data.menuPromoImage ? data.menuPromoImage : ''}
                className="mb-[24px] md:h-full flex flex-col h-full"
                description={t('foodMenu.category.image') as string}
              />
              <Button onClick={handleDelete}>{t('foodMenu.deleteBtn')}</Button>
            </div>
            <div className="w-1/2 flex flex-col md:self-stretch">
              <FileBlock
                onChange={bgFileChange}
                file={bgFile}
                imageFromDB={data.bgImage ? data.bgImage : ''}
                className="mb-[24px] md:h-full flex flex-col"
                description={t('foodMenu.category.image') as string}
                title={t('foodMenu.category.bgImage') as string}
                id="bgImage"
              />
              <Button onClick={handleDeleteBg}>{t('foodMenu.deleteBtn')}</Button>
            </div>
          </div>
          <div className="flex justify-between items-center mb-[16px] md:flex-col md:items-start md:gap-[10px]">
            <Button
              onClick={open}
              ghost={true}
              className="px-[1.354vw] py-[0.625vw] flex gap-[0.521vw] xs:w-full xs:order-2"
            >
              <PlusIcon />
              <p>{t('foodMenu.createCategory')}</p>
            </Button>
            <VisibleLanguageSelect
              refetch={refetch}
              languages={data.languages}
              setActiveLanguages={setActiveLanguages}
              activeLanguages={activeLanguages}
            />
            <Select
              options={defaultLanguageOptions}
              onChange={handleDefaultLanguageChange}
              initialValue={
                data.defaultLanguage
                  ? {
                      value: data.defaultLanguage,
                      title: data.defaultLanguage?.toUpperCase(),
                    }
                  : undefined
              }
              placeholder={t('foodMenu.defaultLang') as string}
            />
            <MenuLanguageSelect items={data?.languages} refetch={refetch} />
          </div>
          {!!adminId && (
            <button
              className="mb-[16px] text-mainBlack xs:text-ellipsis xs:overflow-hidden xs:w-full xs:text-center"
              onClick={copyHandler}
            >
              {t('foodMenu.link')}: {data.link}
            </button>
          )}
          {data && menuLang && (
            <div className="mt-4 flex-col flex gap-4">
              <div className="w-1/2 mb-4">
                <label className="flex flex-col gap-2 mb-2">
                  <span>{t('foodMenu.uploadPdf')}</span>
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={handlePdfUpload}
                    className="block w-full text-sm text-gray-500
                      file:mr-4 file:py-2 file:px-4
                      file:rounded-md file:border-0
                      file:text-sm file:font-semibold
                      file:bg-mainOrange file:text-white
                      hover:file:bg-mainOrange/80 file:cursor-pointer"
                  />
                </label>
                {data.menu_pdfs?.find(pdf => pdf.lang === menuLang) && (
                  <div className="flex gap-4 items-center">
                    <button
                      className="bg-mainOrange hover:bg-mainOrange/80 text-white rounded-md p-2"
                      onClick={handlePdfDelete}
                    >
                      {t('foodMenu.deletePdf')}
                    </button>
                    <p>{data.menu_pdfs?.find(pdf => pdf.lang === menuLang)?.pdfUrl}</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <Button ghost={true} onClick={createMenuHandler} className="mb-[16px]">
          {t('foodMenu.createBtn')}
        </Button>
      )}
      <div
        className={`p-[1.042vw] shadow-org rounded-[0.625vw] h-[44.479vw] flex flex-col gap-[1.042vw]
           md:p-[2.604vw] md:rounded-[1.563vw] md:h-[113.542vw] md:gap-[2.083vw]
           xs:p-[5.333vw] xs:rounded-[3.2vw] xs:h-[165.333vw] xs:gap-[3.2vw] md:overflow-hidden`}
      >
        <p className="text-mainBlack text-[1.25vw] leading-[1.771vw] md:text-[2.604vw] md:leading-[3.906vw] xs:text-[4.533vw] xs:leading-[6.4vw]">
          {t('foodMenu.table.categoryTitle')}
        </p>
        <SearchBar searchValue={searchValue} handleSearch={handleSearch} />
        {data && !!categories.length ? (
          <>
            <Table
              data={categories}
              maxOrder={data.maxOrder}
              refetch={refetch}
              updateFn={update}
              acceptHandler={editHandler}
              deleteHandler={deleteHandler}
              visibilityHandler={visibilityHandler}
              link={true}
            />
            <Pagination
              page={page}
              setPage={setPage}
              pages={data.menu_categories.length}
              onPageChange={onPageChange}
            />
          </>
        ) : (
          <p
            className={`text-[1.25vw] leading-[1.771vw] text-lowGray mt-[2.083vw] text-center
            md:mt-[5.208vw] xs:mt-[10.667vw] md:text-[2.083vw] md:leading-[3.125vw] xs:text-[3.733vw] xs:leading-[5.333vw]`}
          >
            {t('organizations.noData')}
          </p>
        )}
        {isOpen && (
          <CreateCategoryModal onClose={close} refetch={refetch} maxOrder={data?.maxOrder} />
        )}
      </div>
    </div>
  );
};

export default MenuBody;
