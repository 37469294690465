import { FC, HTMLAttributes, PropsWithChildren } from 'react';

import { ReactComponent as Feedback } from '../../../../static/feedback.svg';
import { ReactComponent as Tips } from '../../../../static/payment.svg';
import { ReactComponent as Menu } from '../../../../static/restaraunt.svg';

interface IProps {
  dark: boolean;
  icon: string;
  onClick?: () => void;
  link?: string;
  style?: HTMLAttributes<HTMLAnchorElement>['style'];
}

const icons = {
  restaraunt: Menu,
  feedback: Feedback,
  payment: Tips,
};

const MenuButton: FC<PropsWithChildren<IProps>> = ({
  dark,
  children,
  icon,
  link,
  style,
  onClick,
}) => {
  const Icon = icons[icon as keyof typeof icons];

  return (
    <a
      className={`${
        dark
          ? ' dark:bg-bgNeutral60 bg-white text-black dark:text-white'
          : 'text-white bg-menuBtnGradient'
      } rounded-[5.333vw] py-[5.333vw] px-[9.333vw] flex items-center gap-[7px] justify-center text-[24px] leading-[28px] font-bold whitespace-nowrap`}
      onClick={onClick}
      type="button"
      href={link ? link : undefined}
      style={style}
    >
      <Icon
        className={`${
          dark ? '[&>path]:dark:fill-white [&>path]:fill-black' : '[&>path]:fill-white fill-white'
        } shrink-0`}
        width={24}
        height={24}
      />
      {children}
    </a>
  );
};

export default MenuButton;
